import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#320a8d',
    },
    secondary: {
      main: '#ae52d4',
    },
    kyc: {
      main: '#3ab03e',
      secondary: '#255c27',
    },
    text: {
      primary: '#320a8d',
      secondary: '#858EC6',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '4px',
          padding: '8px 16px',
          fontSize: '15px',
          lineHeight: 2,
        },
        sizeSmall: {
          borderRadius: '4px',
          fontSize: '14px',
          fontWeight: 400,
          padding: '6px 16px',
        },
        containedSecondary: {
          borderRadius: '4px',
          border: '4px solid #fff',
          background: 'linear-gradient(139deg, #F0F2FC 0%, #FFF 100%)',
          color: '#320A8D',
          boxShadow: 'none',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
        notchedOutline: {
          border: '1px solid #320a8d',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: '8px',
        },
      },
    },
  },
});

export default theme;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, useHistory, useLocation, Redirect } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton as MuiListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { setUserDetails } from '../../store/action';
import { Routes } from '../../routes';
import Profile from '../Profile';
import Questionnaire from './questionnaire';
import AdminPanel from './admin-panel';
import Labeling from './labeling';
import notifier from '../../service/notify.service';
import logoImg from '../../assets/images/logo.png';
import appLogoImg from '../../assets/images/app_logo.png';
import jobsIcon from '../../assets/icons/jobs.svg';
import settingsIcon from '../../assets/icons/settings.svg';
import profileIcon from '../../assets/icons/profile.svg';
import helpIcon from '../../assets/icons/help.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import { logOut } from '../../service/user.service';

import CVAT from './cvat';
import { Footer } from '../../components/footer';

const drawerWidth = 256;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const MobileDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  '&.MuiListItemButton-root': {
    padding: '8px 12px',
    [theme.breakpoints.up('sm')]: {
      padding: '8px 16px',
    },
  },
}));

const WorkSpace = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user = {}, isAuthed, refreshToken } = useSelector((state) => state.auth);
  const isAdmin = user?.role === 'admin';
  const isCvatTester = user?.isCvatTester;
  const [value, setValue] = useState(location.pathname);

  if (!isAuthed) {
    history.push({ pathname: Routes.Home.path });
  }

  // const isQuestionnaireFilled = Boolean(user?.misc && user.misc.questionnaire.length > 0);
  // const isWalletFilled = Boolean(user?.polygonWalletAddr);
  const isLabelingEnabled = user?.isKYCed && user?.polygonWalletAddr;

  // const defaultRoute = !isQuestionnaireFilled
  //   ? Routes.Workspace.Questionnaire.path
  //   : Routes.Workspace.Profile.path;
  const defaultRoute = Routes.Workspace.Profile.path;

  // if (location.pathname === Routes.Workspace.Questionnaire.path && isQuestionnaireFilled) {
  //   history.push({ pathname: Routes.Workspace.Profile.path });
  // }

  const onSubmitQuestionnaire = (updatedUser) => {
    notifier.success('Questionnaire has been completed');
    history.push({ pathname: Routes.Workspace.Profile.path });
    dispatch(setUserDetails(updatedUser));
  };

  const handleNavItemClick = (route) => {
    setValue(route);
    history.push(route);
  };

  const handleLogout = () => {
    logOut(refreshToken)
      .catch(() => {})
      .finally(() => {
        dispatch({ type: 'AUTH_SIGN_OUT', payload: false });
        history.push({ pathname: Routes.Home.path });
      });
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleMobileDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [jobsMenuOpen, setJobsMenuOpen] = useState(true);

  const renderDrawer = (_isMobile) => (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      onClick={() => {
        if (isMobile) {
          setMobileOpen(true);
        }
      }}
    >
      <Box>
        <Toolbar sx={{ height: '72px' }}>
          {_isMobile ? (
            <Box
              component="img"
              src={appLogoImg}
              alt="HUMAN App"
              sx={{ width: 16, marginLeft: '8px', cursor: 'pointer' }}
            />
          ) : (
            <Box component="img" src={logoImg} alt="HUMAN App" sx={{ width: 135 }} />
          )}
        </Toolbar>
        <List>
          <ListItem>
            <ListItemButton onClick={() => setJobsMenuOpen(!jobsMenuOpen)}>
              <ListItemIcon>
                <img src={jobsIcon} alt="Data Labelling" />
              </ListItemIcon>
              <ListItemText primary="Jobs" />
              {jobsMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ padding: 0 }}>
            <Collapse in={jobsMenuOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ paddingLeft: '64px' }}>
                <ListItemButton onClick={() => handleNavItemClick(Routes.Workspace.Labeling.path)}>
                  <ListItemText
                    primary="Data Labeling"
                    sx={{
                      textDecoration: value === Routes.Workspace.Labeling.path ? 'underline' : '',
                    }}
                  />
                </ListItemButton>
                {isCvatTester && (
                  <ListItemButton
                    disabled={!isLabelingEnabled}
                    onClick={() => handleNavItemClick(Routes.Workspace.CVAT.path)}
                  >
                    <ListItemText
                      primary="CVAT Labeling"
                      sx={{
                        textDecoration: value === Routes.Workspace.CVAT.path ? 'underline' : '',
                      }}
                    />
                  </ListItemButton>
                )}
              </List>
            </Collapse>
          </ListItem>
          {isAdmin && (
            <ListItem>
              <ListItemButton onClick={() => handleNavItemClick(Routes.Workspace.AdminPanel.path)}>
                <ListItemIcon>
                  <img src={settingsIcon} alt="Admin Panel" />
                </ListItemIcon>
                <ListItemText
                  primary="Admin Panel"
                  sx={{
                    textDecoration: value === Routes.Workspace.AdminPanel.path ? 'underline' : '',
                  }}
                />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Box>
      <Box sx={{ pb: '36px' }}>
        <List>
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <img src={helpIcon} alt="Help" />
              </ListItemIcon>
              <ListItemText primary="Help" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => handleNavItemClick(Routes.Workspace.Profile.path)}>
              <ListItemIcon>
                <img src={profileIcon} alt="Profile" />
              </ListItemIcon>
              <ListItemText
                primary="Profile"
                sx={{ textDecoration: value === Routes.Workspace.Profile.path ? 'underline' : '' }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem>
            {_isMobile ? (
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <img src={logoutIcon} alt="Logout" />
                </ListItemIcon>
              </ListItemButton>
            ) : (
              <Button
                onClick={handleLogout}
                variant="outlined"
                color="primary"
                sx={{ width: '100%', mx: '16px', mt: '16px' }}
              >
                Log out
              </Button>
            )}
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        {isMobile ? (
          <>
            <MobileDrawer
              variant="permanent"
              open={false}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {renderDrawer(true)}
            </MobileDrawer>
            {mobileOpen && (
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleMobileDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                    borderRight: 'none',
                    zIndex: 900,
                  },
                }}
              >
                {renderDrawer(false)}
              </Drawer>
            )}
          </>
        ) : (
          <Drawer
            variant="permanent"
            open
            sx={{
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                borderRight: 'none',
                zIndex: 900,
              },
            }}
          >
            {renderDrawer(false)}
          </Drawer>
        )}
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: { xs: 3, sm: 4 },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          minHeight: '100vh',
          background: '#F6F7FE',
        }}
      >
        <Toolbar sx={{ height: '72px' }} />
        <Box pt={{ xs: 3, sm: 0 }}>
          <Switch>
            <Route path={Routes.Workspace.Labeling.path}>
              <Labeling
                hcaptchaSiteKey={user?.hcaptchaSiteKey}
                userId={user?.id}
                isKYCed={user?.isKYCed}
                polygonWalletAddr={user?.polygonWalletAddr}
              />
            </Route>
            <Route path={Routes.Workspace.CVAT.path}>
              <CVAT />
            </Route>
            <Route path={Routes.Workspace.Questionnaire.path}>
              <Questionnaire userId={user?.id} onSubmit={onSubmitQuestionnaire} />
            </Route>
            <Route path={Routes.Workspace.Profile.path}>
              <Profile />
            </Route>
            <Route path={Routes.Workspace.AdminPanel.path}>
              <AdminPanel isUserAdmin={isAdmin} />
            </Route>
            <Redirect from="*" to={defaultRoute} />
          </Switch>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default WorkSpace;

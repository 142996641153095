import { Stack, Box, Button, Tabs, Tab } from '@mui/material';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import notifier from '../../../service/notify.service';
import { config } from '../../../config';
import { Routes } from '../../../routes';
import * as userService from '../../../service/user.service';
import { setUserDetails } from '../../../store/action';
import MyCvatJobs from './my-jobs';
import AvailableCvatJobs from './available-jobs';

const CVAT = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user = {} } = useSelector((state) => state.auth);
  if (!user?.isCvatTester) history.push({ pathname: Routes.Workspace.Profile.path });
  const [tabValue, setTabValue] = useState(0);
  const [isBinding, setIsBinding] = useState(false);

  const handleBindCvatAccount = async () => {
    if (!user) return;

    setIsBinding(true);
    try {
      const updatedUser = await userService.bindCvatAccount(user?.id);
      dispatch(setUserDetails(updatedUser));
    } catch (error) {
      if (error.message) {
        notifier.error(error.message);
      } else {
        notifier.error('Something went wrong, please try again');
      }
    }
    setIsBinding(false);
  };

  const handleCreateCvatAccount = () => {
    window.open(`${config.cvatUrl}/auth/register`);
  };

  const handleTabChange = (e, newValue) => setTabValue(newValue);

  return user?.cvatId ? (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="My Jobs" />
          <Tab label="Available Jobs" />
        </Tabs>
      </Box>
      <Box pt={2}>
        {tabValue === 0 && <MyCvatJobs />}
        {tabValue === 1 && <AvailableCvatJobs />}
      </Box>
    </Box>
  ) : (
    <Stack spacing={2} sx={{ pt: 2, maxWidth: '400px', mx: 'auto' }}>
      <Button
        variant="outlined"
        sx={{ width: '100%' }}
        onClick={handleBindCvatAccount}
        disabled={isBinding}
      >
        {isBinding ? 'Binding your CVAT account' : 'I have a CVAT account'}
      </Button>
      <Button variant="outlined" onClick={handleCreateCvatAccount}>
        I don't have a CVAT account
      </Button>
    </Stack>
  );
};

export default CVAT;

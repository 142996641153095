import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { register, resendEmailVerification } from '../../../service/user.service';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useRegisterContext } from '../RegisterProvider';
import { RegisterPageHeader } from '../RegisterPageHeader';

const Register = () => {
  const dispatch = useDispatch();
  const { newUser, goToPrevStep, goToNextStep } = useRegisterContext();
  const { openSnackbar } = useSnackbar();

  const handleRegister = () => {
    return register(newUser)
      .then((response) => {
        dispatch({
          type: 'AUTH_SIGN_IN',
          payload: response.isEmailVerified,
        });
        dispatch({
          type: 'AUTH_SUCCESS',
          payload: response,
        });
        return response.token;
      })
      .then((newToken) => resendEmailVerification(newToken))
      .then(() => {
        goToNextStep();
      })
      .catch(() => {
        openSnackbar('Register failed!', 'error');
      });
  };

  return (
    <Stack
      sx={{ maxWidth: '352px', mx: 'auto', py: { xs: '64px', sm: '212px' } }}
      gap={{ xs: 3, sm: 5 }}
    >
      <RegisterPageHeader title="Register" />
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: '24px', sm: '42px' },
            wordBreak: 'break-all',
          }}
        >
          <Box>
            <Typography
              variant="body2"
              color="primary"
              fontWeight={600}
              mb={{ xs: '8px', sm: '20px' }}
            >
              Name
            </Typography>
            <Typography color="primary">{newUser?.name || 'Full Name'}</Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              color="primary"
              fontWeight={600}
              mb={{ xs: '8px', sm: '20px' }}
            >
              Country
            </Typography>
            <Typography color="primary">{newUser?.country || 'Country'}</Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              color="primary"
              fontWeight={600}
              mb={{ xs: '8px', sm: '20px' }}
            >
              Email
            </Typography>
            <Typography color="primary">{newUser?.email || 'Email'}</Typography>
          </Box>
          <Box>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              sx={{ width: '100%', mb: '16px' }}
              onClick={goToPrevStep}
            >
              Edit details
            </Button>
            <Button fullWidth color="primary" variant="contained" onClick={handleRegister}>
              Register
            </Button>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default Register;

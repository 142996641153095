import { useState } from 'react';
import { getHmtDailySpend } from '../service/labelingHcaptcha.service';
import usePollingEffect from './usePollingEffect';
import { config } from '../config';

export default function useDailySpend({ siteKey }) {
  const [dailyHmtSpend, setDailyHmtSpend] = useState(0);
  const [error, setError] = useState(null);

  const getDailySpend = () => {
    getHmtDailySpend()
      .then((stats) => {
        setDailyHmtSpend(stats.spend);
      })
      .catch((err) => {
        setError(err);
      });
  };

  usePollingEffect(
    () => {
      if (siteKey) {
        getDailySpend();
      }
    },
    [siteKey],
    { interval: config.captchaStatsPollingInterval * 1000 },
  );

  return { dailyHmtSpend, error };
}

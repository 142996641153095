import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { Routes } from '../../routes';
import { Card } from '../../components/card';
import VeriffButton from '../../components/veriff';
import userSvg from '../../assets/icons/user.svg';
import HcaptchaStatistics from '../../components/statistics';
import ConnectWalletButton from '../../components/connect-wallet';
import { UpdatePasswordModal, UpdatePasswordModalForm } from '../../components/update-password';
import { UpdateWalletForm } from '../../components/update-wallet-form';

const ProfilePage = (props) => {
  const { history } = props;
  const { user, isAuthed } = useSelector((state) => state.auth);
  if (!isAuthed) history.push({ pathname: Routes.Home.path });

  const [passwordModalShow, setPasswordModalShow] = useState(false);
  const [updatingWalletAddress, setUpdatingWalletAddress] = useState(false);
  const [mobileConnectWallet, setMobileConnectWallet] = useState(false);
  const [mobileUpdatePassword, setMobileUpdatePassword] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleUpdatePasswordClick = () => {
    if (isMobile) {
      setMobileUpdatePassword(true);
    } else {
      setPasswordModalShow(true);
    }
  };

  if (mobileUpdatePassword) {
    return (
      <UpdatePasswordModalForm
        onCancel={() => setMobileUpdatePassword(false)}
        onSuccess={() => setMobileUpdatePassword(false)}
      />
    );
  }

  return (
    <>
      <Box sx={{ px: { xs: 0, sm: 6 }, position: 'relative' }}>
        <Box display="flex" alignItems="center">
          <Box
            component="img"
            src={userSvg}
            alt="profile"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          />
          <Typography variant={isMobile ? 'h6' : 'h3'} color="primary" ml={{ xs: 0, sm: 5 }}>
            Profile
          </Typography>
        </Box>
        {user?.hcaptchaSiteKey && <HcaptchaStatistics siteKey={user?.hcaptchaSiteKey} />}
      </Box>
      <Card>
        <Box>
          <Typography variant="body2" color="primary" fontWeight={600} mb="20px">
            Name
          </Typography>
          <Typography color="primary">{user?.name || 'Full Name'}</Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="primary" fontWeight={600} mb="20px">
            Country
          </Typography>
          <Typography color="primary">{user?.country || 'Country'}</Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="primary" fontWeight={600} mb="20px">
            Email
          </Typography>
          <Typography color="primary">{user?.email || 'Email'}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '14px',
            justifyContent: 'flex-start',
          }}
        >
          <Typography variant="body2" color="primary" fontWeight={600}>
            Password
          </Typography>
          <Typography color="primary">*********</Typography>
          <Typography
            sx={{ fontSize: '12px', color: '#6309ff', cursor: 'pointer', textAlign: 'left' }}
            onClick={handleUpdatePasswordClick}
          >
            Update password?
          </Typography>
        </Box>
        {(user?.polygonWalletAddr || mobileConnectWallet) && (
          <Box>
            <Typography variant="body2" color="primary" fontWeight={600} mb="20px">
              Wallet address
            </Typography>
            {!updatingWalletAddress ? (
              <Typography color="primary" variant="caption" fontWeight={500}>
                {user?.polygonWalletAddr}
              </Typography>
            ) : (
              <UpdateWalletForm
                onCancel={() => {
                  setUpdatingWalletAddress(false);
                  setMobileConnectWallet(false);
                }}
                onSuccess={() => {
                  setUpdatingWalletAddress(false);
                }}
              />
            )}
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {!user?.polygonWalletAddr &&
            (!isMobile ? (
              <ConnectWalletButton />
            ) : (
              !mobileConnectWallet && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setMobileConnectWallet(true);
                    setUpdatingWalletAddress(true);
                  }}
                >
                  Connect Wallet
                </Button>
              )
            ))}
          {user?.polygonWalletAddr && !updatingWalletAddress && (
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => setUpdatingWalletAddress(true)}
            >
              Change wallet address
            </Button>
          )}
          {!user?.isKYCed && <VeriffButton />}
        </Box>
      </Card>
      <UpdatePasswordModal show={passwordModalShow} toggle={setPasswordModalShow} />
    </>
  );
};
ProfilePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default withRouter(ProfilePage);

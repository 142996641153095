import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useVeriff } from '../../../components/veriff/useVeriff';
import { useRegisterContext } from '../RegisterProvider';
import { Routes } from '../../../routes';

const KYC = () => {
  const { goToNextStep } = useRegisterContext();
  const [show, setShow] = useState(true);
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useVeriff({ show, setShow, onSuccess: goToNextStep });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '352px',
        alignItems: 'justify-content',
        mx: 'auto',
        pb: '112px',
        pt: { xs: '64px', sm: '200px' },
      }}
    >
      {isMobile && (
        <>
          <Typography color="primary" variant="h6">
            KYC
          </Typography>
          <Typography color="primary" mt="36px" mb="24px">
            It’s now time for you to start your identity verification process.
          </Typography>
        </>
      )}
      <Box id="veriff-root" sx={{ mb: 15 }} />
      <Stack direction="row" spacing="12px">
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => history.push(Routes.Home.path)}
        >
          Cancel
        </Button>
        <Button fullWidth variant="contained" color="primary" onClick={goToNextStep}>
          Skip for now
        </Button>
      </Stack>
    </Box>
  );
};

export default KYC;

import { toast } from 'react-toastify';

const toastConfig = {
  position: toast.POSITION.TOP_CENTER,
};
export default {
  success: (message) => {
    toast.dismiss();
    toast.success(message, toastConfig);
  },
  warning: (message) => {
    toast.dismiss();
    toast.warn(message, toastConfig);
  },
  error: (message) => {
    toast.dismiss();
    toast.error(message, toastConfig);
  },
};

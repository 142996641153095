import React from 'react';
import { Formik, Form } from 'formik';
import { Button, TextField, FormControl, Typography, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { makeCvatTesters } from '../../../service/user.service';
import { startGlobalLoading, finishGlobalLoading } from '../../../store/action';
import { useSnackbar } from '../../../providers/SnackbarProvider';

export default function MakeCvatTesters() {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        userEmails: '',
      }}
      onSubmit={({ userEmails }) => {
        dispatch(startGlobalLoading());
        makeCvatTesters(userEmails)
          .then((data) => {
            if (data.failedEmails.length !== 0) {
              openSnackbar(
                `Following users were not added to CVAT beta: ${data.failedEmails}`,
                'warning',
              );
            } else {
              openSnackbar(`All users were added to the CVAT beta`, 'success');
            }
          })
          .catch((error) => {
            openSnackbar(`Something went wrong: ${error.message}`, 'error');
          })
          .finally(() => dispatch(finishGlobalLoading()));
      }}
    >
      {(formikObj) => {
        return (
          <Form className="makeCvatTesters" style={{ marginBottom: 0 }}>
            <Typography
              sx={{ fontSize: { xs: '16px', sm: '24px' }, fontWeight: 500 }}
              mb={{ xs: 2, md: 5 }}
            >
              Add users to CVAT beta testing
            </Typography>
            <Box sx={{ display: 'flex', gap: { xs: 2, md: 4 }, flexWrap: 'wrap' }}>
              <FormControl sx={{ flex: 1 }}>
                <TextField
                  id="userEmails"
                  label="User emails"
                  helperText="Input emails separated by a comma"
                  value={formikObj.values.userEmails}
                  onChange={formikObj.handleChange}
                />
              </FormControl>
              <Button
                variant="contained"
                sx={{ height: '56px', width: { xs: '100%', md: '104px' } }}
                onClick={formikObj.submitForm}
              >
                Add
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

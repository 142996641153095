import { Box } from '@mui/material';
import React from 'react-redux';
import { withRouter } from 'react-router-dom';
import Welcome from './welcome';

const HomePage = () => {
  return (
    <Box>
      <Welcome />
    </Box>
  );
};

export default withRouter(HomePage);

import * as Yup from 'yup';
import { errors } from '../../constants';

export const ProfileValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required(errors.errorMessage.requireUserName),
    email: Yup.string()
      .email(errors.errorMessage.invalidEmail)
      .required(errors.errorMessage.requireEmail),
    country: Yup.string().required(errors.errorMessage.requireCountry),
    polygonWalletAddr: Yup.string()
      .length(42, errors.errorMessage.invalidLengthWalletAddress)
      .required(errors.errorMessage.requireWalletAddress),
  })
  .required(errors.errorMessage.requireProfileDetails);

export const UpdatePasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required(errors.errorMessage.requirePassword),
  newPassword: Yup.string()
    .required(errors.errorMessage.requirePassword)
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!*]).*$/,
      errors.errorMessage.weakPassword,
    )
    .min(8, errors.errorMessage.invalidPasswordLength)
    .max(255, errors.errorMessage.invalidPasswordMaxLength),
  newPasswordConfirmation: Yup.string()
    .required(errors.errorMessage.requirePassword)
    .when('password', {
      is: (password) => !!(password && password.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], errors.errorMessage.notConfirmedPassword),
    }),
  hcaptchaToken: Yup.string().required(errors.errorMessage.captchaPassRequired),
});

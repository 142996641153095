import {
  Box,
  Button,
  Card,
  Collapse,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useCaptchaStats from '../../hooks/useCaptchaStats';

const StatsInner = ({ siteKey }) => {
  const { servedCaptchas, solvedCaptchas, hmtTotal, hmtRecent, getCaptchaStats } = useCaptchaStats({
    siteKey,
  });

  return (
    <Box>
      <Box mt={4}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography color="primary" variant="caption" component="p">
              Jobs served
            </Typography>
            <Typography color="primary" variant="h5" fontWeight={500}>
              {servedCaptchas}
            </Typography>
          </Box>
          <Box>
            <Typography color="primary" variant="caption" component="div">
              Jobs completed
            </Typography>
            <Typography color="primary" variant="h5" fontWeight={500}>
              {solvedCaptchas}
            </Typography>
          </Box>
        </Box>
        <Box mt="18px">
          <Typography color="primary" variant="caption" component="p">
            HMT earned
          </Typography>
          <Typography color="primary" variant="h5" fontWeight={500}>
            {hmtTotal} HMT
          </Typography>
        </Box>
        <Box mt="18px">
          <Typography color="primary" variant="caption" component="p">
            HMT in last 1 hour
          </Typography>
          <Typography color="primary" variant="h5" fontWeight={500}>
            <span style={{ color: '#6309ff' }}>{hmtRecent}</span> HMT
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 6 }}>
        <Typography color="primary" variant="body2" fontSize={10} fontStyle="italic">
          Statistics are not live.
          <br /> Please refresh to view live statistics.
        </Typography>
        <IconButton color="primary" aria-label="Refresh" onClick={getCaptchaStats}>
          <AutorenewIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
StatsInner.propTypes = {
  siteKey: PropTypes.string,
};
StatsInner.defaultProps = {
  siteKey: '',
};

export const Statistics = ({ siteKey }) => {
  const [show, setShow] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [modalShow, setModalShow] = useState(false);

  if (isMobile) {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          sx={{ position: 'absolute', top: '-72px', right: 0 }}
          onClick={() => setModalShow(true)}
        >
          Statistics
        </Button>
        <Modal id="modal__connect_wallet" open={modalShow} onClose={() => setModalShow(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '10%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '80%',
              maxWidth: 400,
              bgcolor: 'background.paper',
              borderRadius: '4px',
              border: '1px solid rgba(218, 222, 240, 0.80)',
              px: '36px',
              pt: '30px',
              pb: '40px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="body2" fontWeight={600}>
                Statistics
              </Typography>
              <IconButton color="primary" onClick={() => setModalShow(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <StatsInner siteKey={siteKey} />
          </Box>
        </Modal>
      </>
    );
  }

  return (
    <Card
      sx={{
        position: 'absolute',
        right: '48px',
        top: 0,
        borderRadius: '16px',
        width: '284px',
        mx: 'auto',
        padding: '12px 24px 12px 32px',
        border: '1px solid rgba(218, 222, 240, 0.80)',
        boxShadow: 'none',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Typography variant="body2" fontWeight={600} onClick={() => setShow(!show)}>
          Statistics
        </Typography>
        <IconButton onClick={() => setShow(!show)}>
          {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Box>
      <Collapse in={show}>
        <StatsInner siteKey={siteKey} />
      </Collapse>
    </Card>
  );
};

Statistics.propTypes = {
  siteKey: PropTypes.string,
};

Statistics.defaultProps = {
  siteKey: '',
};

export default Statistics;

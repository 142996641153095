import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Link, Modal, Stack, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleFilledImg from '../../assets/images/CheckCircleFilled.png';
import { WalletAddressValidationSchema } from '../update-wallet-form/schema';
import { update } from '../../service/user.service';
import { useSnackbar } from '../../providers/SnackbarProvider';

export const ConnectWalletModal = ({ show, toggle }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { openSnackbar } = useSnackbar();
  const [isSuccess, setIsSuccess] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(null);
  const initialValues = {
    polygonWalletAddr: '',
  };

  const handleClose = () => {
    if (updatedUser) {
      dispatch({ type: 'SET_USER', payload: updatedUser });
    }
    toggle(false);
  };

  const handleUpdateWalletAddress = ({ polygonWalletAddr }) => {
    update(user.id, { polygonWalletAddr })
      .then((userRes) => {
        if (userRes) {
          setIsSuccess(true);
          setUpdatedUser(userRes);
        } else {
          openSnackbar('Failed to connect the wallet', 'error');
        }
      })
      .catch((err) => {
        openSnackbar(err.message, 'error');
      });
  };

  return (
    <Modal id="modal__connect_wallet" open={show} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          minWidth: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '20px',
          py: 25,
        }}
      >
        <Stack sx={{ width: 352, mx: 'auto' }} spacing="42px">
          <Typography color="text.primary" variant="h4" fontWeight={600}>
            Connect Wallet
          </Typography>
          {!isSuccess && (
            <Typography color="text.primary" variant="body2">
              The HUMAN App runs on Polygon mainnet. Please make sure you enter your polygon wallet
              address.{' '}
              <Link href="#" sx={{ fontWeight: 600 }}>
                Learn more
              </Link>
              .
            </Typography>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={WalletAddressValidationSchema}
            onSubmit={handleUpdateWalletAddress}
          >
            {({
              errors,
              touched,
              isValid,
              dirty,
              values,
              handleSubmit,
              handleBlur,
              setFieldValue,
            }) => (
              <Form style={{ display: 'flex', flexDirection: 'column', gap: '42px' }}>
                <TextField
                  fullWidth
                  placeholder="Polygon Wallet Address"
                  type="text"
                  name="polygonWalletAddr"
                  value={values.polygonWalletAddr}
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue('polygonWalletAddr', e.target.value)}
                  error={touched.polygonWalletAddr && errors.polygonWalletAddr}
                  helperText={errors.polygonWalletAddr}
                  inputProps={{ sx: { fontSize: '12px' } }}
                  disabled={isSuccess}
                />
                {!isSuccess && (
                  <Box>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      disabled={!(isValid && dirty)}
                      onClick={handleSubmit}
                      sx={{ mb: 2 }}
                    >
                      Connect Wallet
                    </Button>
                    <Button fullWidth color="primary" variant="outlined" onClick={handleClose}>
                      Cancel
                    </Button>
                  </Box>
                )}
              </Form>
            )}
          </Formik>
          {isSuccess && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 6 }}>
              <Box component="img" src={CheckCircleFilledImg} alt="check" />
              <Typography variant="body2" fontWeight={600} color="primary" sx={{ mt: 2 }}>
                Success! Your wallet is now connected!
              </Typography>
              <Box mt="44px" display="flex" flexDirection="column" sx={{ width: '100%' }}>
                {/* <Button variant="outlined" color="primary" onClick={handleDisconnect}>
                  Disconnect wallet
                </Button> */}
                <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={handleClose}>
                  Close
                </Button>
              </Box>
            </Box>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

ConnectWalletModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

ConnectWalletModal.defaultProps = {};

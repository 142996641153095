import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Button, TextField, FormControl, MenuItem, Typography, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { suspendUsers } from '../../../service/user.service';
import { startGlobalLoading, finishGlobalLoading } from '../../../store/action';
import { useSnackbar } from '../../../providers/SnackbarProvider';

const suspendStatuses = [
  {
    value: 'FAKE_USER',
    label: 'Fake user',
  },
];

export default function SuspendUsers() {
  const dispatch = useDispatch();
  const [suspendStatus, setStatus] = useState('FAKE_USER');
  const { openSnackbar } = useSnackbar();

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <Formik
      initialValues={{
        userEmails: '',
      }}
      onSubmit={({ userEmails }) => {
        dispatch(startGlobalLoading());
        suspendUsers(userEmails, suspendStatus)
          .then((data) => {
            if (data.failedEmails.length !== 0) {
              openSnackbar(`Following users were not suspended: ${data.failedEmails}`, 'warning');
            } else {
              openSnackbar(`All users were suspended`, 'success');
            }
          })
          .catch((error) => {
            openSnackbar(`Something went wrong: ${error.message}`, 'error');
          })
          .finally(() => dispatch(finishGlobalLoading()));
      }}
    >
      {(formikObj) => {
        return (
          <Form className="suspendUsers" style={{ marginBottom: 0 }}>
            <Typography
              sx={{ fontSize: { xs: '16px', sm: '24px' }, fontWeight: 500 }}
              mb={{ xs: 2, md: 5 }}
            >
              Suspend Users
            </Typography>
            <FormControl fullWidth sx={{ mb: { xs: 2, sm: 3 } }}>
              <TextField
                fullWidth
                id="userEmails"
                label="User emails"
                helperText="Input emails separated by a comma"
                value={formikObj.values.userEmails}
                onChange={formikObj.handleChange}
              />
            </FormControl>
            <Grid container spacing={{ xs: 2, sm: 4 }}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    id="suspendStatus"
                    select
                    label="Select"
                    value={suspendStatus}
                    onChange={handleChange}
                    helperText="Please select the reason"
                  >
                    {suspendStatuses.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ height: '56px' }}
                  onClick={formikObj.submitForm}
                >
                  Suspend
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useFormik } from 'formik';
import {
  AppBar,
  Box,
  Button,
  FormHelperText,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { config } from '../../config';
import { startGlobalLoading, finishGlobalLoading } from '../../store/action';
import { Password } from '../../ui/password';
import { signIn } from '../../service/user.service';
import { Routes } from '../../routes';
import logoImg from '../../assets/images/logo.png';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { LoginValidationSchema } from './schema';

export const LoginForm = (props) => {
  const dispatch = useDispatch();
  const captchaRef = useRef(null);
  const { history } = props;
  const [hcaptchaToken, setHcaptchaToken] = useState('');
  const { openSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      token: '',
    },
    validationSchema: LoginValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(startGlobalLoading());
      signIn({ ...values, hcaptchaToken })
        .then((res) => {
          if (res) {
            const { user } = res;
            dispatch({
              type: 'AUTH_SUCCESS',
              payload: res,
            });
            dispatch({
              type: 'AUTH_SIGN_IN',
              payload: user.isEmailVerified,
            });
            setHcaptchaToken('');
            setSubmitting(false);
            if (user.isEmailVerified) history.push({ pathname: Routes.Workspace.path });
            else history.push({ pathname: Routes.Register.path, search: `?step=verify_email` });
          } else {
            setHcaptchaToken('');
            setSubmitting(false);
            captchaRef.current.resetCaptcha();
          }
        })
        .catch(() => {
          openSnackbar('Login failed!', 'error');
          setHcaptchaToken('');
          setSubmitting(false);
          captchaRef.current.resetCaptcha();
        })
        .finally(() => dispatch(finishGlobalLoading()));
    },
  });

  const handleVerificationToken = (token) => {
    setHcaptchaToken(token);
    formik.setFieldValue('token', token);
  };

  return (
    <Box sx={{ maxWidth: '352px', mx: 'auto' }}>
      <form
        style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
        name="form"
        onSubmit={formik.handleSubmit}
      >
        <TextField
          fullWidth
          placeholder="Email"
          type="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors.email}
          helperText={formik.errors.email}
        />
        <Password
          placeholder="Password"
          type="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && formik.errors.password}
          helperText={formik.errors.password}
        />
        <Box textAlign="center">
          <HCaptcha
            sitekey={config.hcaptchaSiteKey}
            endpoint={config.hcaptchaExchangeUrl}
            reportapi={config.hcaptchaLabelingBaseUrl}
            custom
            onVerify={(token) => handleVerificationToken(token)}
            ref={captchaRef}
          />
          {formik.isSubmitting && formik.errors.token && (
            <FormHelperText error sx={{ mx: '14px', mt: '3px' }}>
              {formik.errors.token}
            </FormHelperText>
          )}
        </Box>
        <Link to={{ pathname: '/reset-password' }} style={{ textDecoration: 'none' }}>
          <Typography color="primary" textAlign="center" variant="body2">
            Forgot Password?
          </Typography>
        </Link>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={() => history.push({ pathname: Routes.Home.path })}
          >
            Back
          </Button>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={formik.handleSubmit}
            disabled={!(formik.isValid && formik.dirty && hcaptchaToken)}
          >
            Log in
          </Button>
        </Box>
      </form>
    </Box>
  );
};

LoginForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const LoginPage = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        backgroundColor: { xs: '#fff', sm: '#F6F7FE' },
        px: { xs: '8px', sm: '24px', md: '72px', lg: '100px', xl: '224px' },
        pt: '76px',
        minHeight: '100vh',
        boxSizing: 'border-box',
      }}
    >
      <AppBar
        component="nav"
        sx={{ backgroundColor: { xs: '#fff', sm: '#F6F7FE' }, boxShadow: 'none' }}
      >
        <Toolbar>
          <img src={logoImg} alt="HUMAN App" style={{ width: 135 }} />
        </Toolbar>
      </AppBar>
      <Typography
        color="primary"
        variant={isMobile ? 'h6' : 'h4'}
        fontWeight={600}
        textAlign={isMobile ? 'left' : 'center'}
        mb={2}
        mx="auto"
        maxWidth={{ xs: '352px', sm: '100%' }}
      >
        Log In
      </Typography>
      <LoginForm {...props} />
    </Box>
  );
};
LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(LoginPage);

import { Box, IconButton, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LaunchIcon from '@mui/icons-material/Launch';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as userService from '../../../service/user.service';
import { cvatJobTypes } from '../../../constants/cvat';
import { shortenAddress } from '../../../utils';

export default function MyCvatJobs() {
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user = {} } = useSelector((state) => state.auth);

  const fetchJobs = async () => {
    try {
      setIsLoading(true);
      const data = await userService.getCvatJobs(user?.id, true);
      setJobs(data.filter((job) => job.assignment !== null));
    } catch (err) {
      console.log(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          aria-label="simple table"
          sx={{
            'td, th': { paddingLeft: '8px', paddingRight: '8px' },
            'td:last-child': { paddingLeft: 0 },
            'th:first-child': { paddingLeft: '16px' },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Escrow address</TableCell>
              <TableCell>Job type</TableCell>
              <TableCell>Job description</TableCell>
              <TableCell>Job size</TableCell>
              <TableCell>Job status</TableCell>
              <TableCell sx={{ maxWidth: '160px' }}>Estimated reward (HMT)</TableCell>
              <TableCell>Job URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && jobs.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  Loading...
                </TableCell>
              </TableRow>
            )}
            {jobs.length === 0 && !isLoading ? (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  No Jobs
                </TableCell>
              </TableRow>
            ) : (
              jobs.map((job) => (
                <TableRow key={job.escrow_address}>
                  <TableCell component="th" scope="row">
                    {shortenAddress(job.escrow_address)}
                  </TableCell>
                  <TableCell>{cvatJobTypes[job.job_type]}</TableCell>
                  <TableCell>{job.description}</TableCell>
                  <TableCell>{job.job_size}</TableCell>
                  <TableCell>{job.status}</TableCell>
                  <TableCell>~{job.job_bounty} HMT</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                      <Tooltip title="Go to job">
                        <IconButton onClick={() => window.open(job.assignment.assignment_url)}>
                          <LaunchIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

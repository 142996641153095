import {
  AppBar,
  Box,
  Button,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Routes } from '../../routes';
import { LoginForm } from '../Login';
import homepageImg1 from '../../assets/images/homepage_img_1.png';
import homepageImg2 from '../../assets/images/homepage_img_2.png';
import homepageImg3 from '../../assets/images/homepage_img_3.png';
import logoImg from '../../assets/images/logo.png';

const StyledTab = styled(Tab)((props) => ({
  padding: '36px 0px 20px',
  background: '#e9ebfa',
  borderTop: '16px solid #fff',
  borderLeft: props.value === 0 ? '16px solid #fff' : 'none',
  borderRight: props.value === 1 ? '16px solid #fff' : 'none',
  borderTopLeftRadius: props.value === 0 ? '32px' : 0,
  borderTopRightRadius: props.value === 1 ? '32px' : 0,
  '&.Mui-selected': {
    background: '#fff',
    border: '1px solid #dbe1f6',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    borderBottom: 'none',
  },
}));

const Welcome = ({ history }) => {
  const [tabValue, setTabValue] = useState(0);
  const { isAuthed } = useSelector((state) => state.auth);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (isAuthed) {
    history.push({ pathname: Routes.Workspace.Profile.path });
  }

  return (
    <Box>
      <AppBar component="nav" sx={{ background: '#fff', boxShadow: 'none' }}>
        <Toolbar>
          <img src={logoImg} alt="HUMAN App" style={{ width: 135 }} />
          <Box sx={{ display: { xs: 'none', sm: 'block' }, ml: 'auto' }}>
            <Link href="https://humanprotocol.org">HUMAN Protocol</Link>
            <Link href="https://humanprotocol.org" sx={{ ml: 3 }}>
              How it works
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          px: { sm: 2, md: 8, xl: 30 },
          pt: { xs: 12, sm: 16, xl: 27 },
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={5} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: { xs: 'center', sm: 'flex-start' },
                height: '100%',
              }}
            >
              <Box sx={{ display: 'flex', alignItmes: 'center', ml: { xs: 0, sm: -4 } }}>
                <Box
                  component="img"
                  src={homepageImg1}
                  alt="bag"
                  sx={{ zIndex: 2, width: '142px' }}
                />
                <Box
                  component="img"
                  src={homepageImg2}
                  alt="user"
                  sx={{ ml: { xs: '-90px', md: 0 }, zIndex: 1, width: '142px' }}
                />
                <Box
                  component="img"
                  src={homepageImg3}
                  alt="human"
                  sx={{ ml: { xs: '-90px', md: 0 }, width: '142px' }}
                />
              </Box>
              <Typography color="primary" variant="h2">
                <b>HUMAN</b> App
              </Typography>
              <Typography
                color="primary"
                variant={isMobile ? 'body2' : 'h5'}
                fontWeight={isMobile ? 600 : 400}
                mt={{ xs: 6, md: 4 }}
              >
                Complete jobs, earn HMT.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} md={6}>
            {!isMobile ? (
              <Box
                sx={{
                  mx: 'auto',
                  maxWidth: '560px',
                  height: '580px',
                  background: '#fff',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={(e, newValue) => setTabValue(newValue)}
                  variant="fullWidth"
                  sx={{
                    '& .MuiTabs-indicator': {
                      display: 'none',
                    },
                    borderBottom: '1px solid #fff',
                    marginBottom: '-2px',
                  }}
                >
                  <StyledTab value={0} label="Sign Up" />
                  <StyledTab value={1} label="Log in" />
                </Tabs>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  flex={1}
                  sx={{
                    border: '1px solid #dbe1f6',
                    borderRadius: '20px',
                    borderTopLeftRadius: tabValue === 0 ? '0px' : '20px',
                    borderTopRightRadius: tabValue === 1 ? '0px' : '20px',
                  }}
                >
                  {tabValue === 0 && (
                    <Box px={10} sx={{ textAlign: 'center' }}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => history.push(Routes.Register.path)}
                        sx={{ mb: 4, minWidth: { xs: '303px', md: '376px' } }}
                      >
                        Sign up
                      </Button>
                      <Link
                        href="https://humanprotocol.org/app/terms-and-conditions"
                        target="_blank"
                        sx={{ fontSize: '12px', textAlign: 'center' }}
                      >
                        Terms & conditions
                      </Link>
                    </Box>
                  )}
                  {tabValue === 1 && <LoginForm history={history} />}
                </Box>
              </Box>
            ) : (
              <Box sx={{ maxWidth: '352px', mx: 'auto', mt: '60px' }}>
                <Stack gap="16px">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => history.push(Routes.Register.path)}
                    sx={{ minWidth: { xs: '303px', md: '376px' } }}
                  >
                    Sign up
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push(Routes.Login.path)}
                    sx={{ minWidth: { xs: '303px', md: '376px' } }}
                  >
                    Log in
                  </Button>
                  <Link
                    href="https://humanprotocol.org/app/terms-and-conditions"
                    target="_blank"
                    sx={{ fontSize: '12px', textAlign: 'center' }}
                  >
                    Terms & conditions
                  </Link>
                </Stack>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
Welcome.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Welcome);

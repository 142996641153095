import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Box, Button, FormHelperText, Link, Stack, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import { config } from '../../../config';
import { countries } from '../../../constants';
import { Password } from '../../../ui/password';
import { RegisterValidationSchema } from './schema';
import { useRegisterContext } from '../RegisterProvider';
import { Routes } from '../../../routes';
import { RegisterPageHeader } from '../RegisterPageHeader';

const UserDetails = () => {
  const { newUser, setNewUser, goToNextStep } = useRegisterContext();
  const history = useHistory();
  const captchaRef = useRef(null);

  const initialValues = {
    ...newUser,
    repeatPassword: '',
  };

  const countrySelectOptions = useMemo(() => {
    if (countries.countryList) {
      return countries.countryList.map((country) => ({
        value: country.Code,
        label: country.Name,
      }));
    }
    return [];
  }, [countries]);

  const handleRegister = ({
    name,
    email,
    password,
    country,
    hcaptchaToken,
    areTermsAndConditionsAccepted,
  }) => {
    setNewUser({
      name,
      email,
      password,
      country,
      hcaptchaToken,
      areTermsAndConditionsAccepted,
    });

    goToNextStep();
  };

  return (
    <Stack sx={{ maxWidth: '352px', mx: 'auto', pt: '64px', pb: '30px' }} gap={{ xs: 2, sm: 5 }}>
      <RegisterPageHeader title="User details" />
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={RegisterValidationSchema}
          onSubmit={handleRegister}
        >
          {({
            errors,
            touched,
            values,
            dirty,
            isValid,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <TextField
                fullWidth
                name="email"
                value={values.email}
                onChange={(e) => setFieldValue('email', e.target.value)}
                onBlur={handleBlur}
                placeholder="your@email.com"
                error={touched.email && errors.email}
                helperText={errors.email}
              />
              <TextField
                fullWidth
                name="name"
                value={values.name}
                onChange={(e) => setFieldValue('name', e.target.value)}
                onBlur={handleBlur}
                placeholder="Full name"
                error={touched.name && errors.name}
                helperText={errors.name}
              />
              <Box>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  isSearchable
                  filterOption={createFilter({ matchFrom: 'start' })}
                  name="country"
                  options={countrySelectOptions}
                  onChange={(country) => {
                    if (country) {
                      setFieldValue('country', country.value);
                    } else {
                      setFieldTouched('country', true);
                      setFieldValue('country', '');
                    }
                  }}
                  placeholder="Country"
                  styles={{
                    valueContainer: (styles) => ({
                      ...styles,
                      padding: '16.5px 14px',
                    }),
                    input: (styles) => ({
                      ...styles,
                      padding: 0,
                      margin: 0,
                    }),
                    control: (styles) => ({
                      ...styles,
                      borderRadius: '4px',
                      border: '1px solid #320a8d',
                    }),
                    singleValue: (styles) => ({
                      ...styles,
                      color: '#320a8d',
                    }),
                    menu: (styles) => ({ ...styles, zIndex: 1000 }),
                  }}
                  value={countrySelectOptions.find((option) => option.value === values.country)}
                />
                {touched.country && errors.country && (
                  <FormHelperText sx={{ mx: '14px', mt: '3px' }} error>
                    {errors.country}
                  </FormHelperText>
                )}
              </Box>
              <Password
                onChange={(e) => setFieldValue('password', e.target.value)}
                onBlur={handleBlur}
                name="password"
                value={values.password}
                placeholder="Create password"
                error={touched.password && errors.password}
                helperText={errors.password}
              />
              <Password
                onChange={(e) => setFieldValue('repeatPassword', e.target.value)}
                onBlur={handleBlur}
                name="repeatPassword"
                value={values.repeatPassword}
                placeholder="Confirm password"
                error={touched.repeatPassword && errors.repeatPassword}
                helperText={errors.repeatPassword}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <HCaptcha
                  sitekey={config.hcaptchaSiteKey}
                  endpoint={config.hcaptchaExchangeUrl}
                  reportapi={config.hcaptchaLabelingBaseUrl}
                  custom
                  onVerify={(token) => setFieldValue('hcaptchaToken', token)}
                  ref={captchaRef}
                />
                {errors.hcaptchaToken && (
                  <FormHelperText sx={{ mx: '14px', mt: '3px' }} error>
                    {errors.captchPassRequired}
                  </FormHelperText>
                )}
              </Box>
              <Stack direction="row" gap="16px">
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  onClick={() => history.push({ pathname: Routes.Home.path })}
                >
                  Cancel
                </Button>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!(isValid && dirty)}
                >
                  Next
                </Button>
              </Stack>
              <Typography color="primary" component="p" variant="caption" textAlign="center">
                By clicking "Next" you agree with our{' '}
                <Link
                  href="https://humanprotocol.org/app/terms-and-conditions"
                  target="_blank"
                  sx={{ fontWeight: 600, textDecoration: 'underline' }}
                >
                  terms and conditions
                </Link>
              </Typography>
            </form>
          )}
        </Formik>
      </Box>
    </Stack>
  );
};

export default UserDetails;

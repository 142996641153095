export const config = {
  apiUrl: window.env.REACT_APP_API_URL,
  hcaptchaSiteKey: window.env.REACT_APP_HCAPTCHA_SITE_KEY,
  veriffApiKey: window.env.REACT_APP_VERIFF_API_KEY,
  hcaptchaLabelingBaseUrl: window.env.REACT_APP_HCAPTCHA_LABELING_BASE_URL,
  hcaptchaExchangeUrl: window.env.REACT_APP_HCAPTCHA_EXCHANGE_URL,
  cvatUrl: window.env.REACT_APP_CVAT_URL,
  hmtDailyLimit: window.env.REACT_APP_HMT_DAILY_LIMIT,
  dailySolvedCaptchaLimit: window.env.REACT_APP_DAILY_SOLVED_CAPTCHA_LIMIT,
  captchaStatsPollingInterval: window.env.REACT_APP_CAPTCHA_STATS_POLLING_INT,
  gtmId: window.env.REACT_APP_GTM_ID,
};

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

export const RegisterSteps = {
  USER_DETAILS: 'user_details',
  REGISTER: 'register',
  VERIFY_EMAIL: 'verify_email',
  KYC: 'kyc',
  CONNECT_WALLET: 'connect_wallet',
};

export const RegisterContext = createContext({});

export const useRegisterContext = () => useContext(RegisterContext);

export const RegisterProvider = ({ children }) => {
  const { search } = useLocation();
  const [newUser, setNewUser] = useState({
    email: '',
    name: '',
    password: '',
    country: '',
    hcaptchaToken: '',
    areTermsAndConditionsAccepted: true,
  });
  const [step, setStep] = useState(RegisterSteps.USER_DETAILS);
  const [userRegistered, setUserRegistered] = useState(false);

  const goToNextStep = () => {
    if (step === RegisterSteps.USER_DETAILS) setStep(RegisterSteps.REGISTER);
    if (step === RegisterSteps.REGISTER) {
      setUserRegistered(true);
      setStep(RegisterSteps.VERIFY_EMAIL);
    }
    if (step === RegisterSteps.VERIFY_EMAIL) setStep(RegisterSteps.KYC);
    if (step === RegisterSteps.KYC) setStep(RegisterSteps.CONNECT_WALLET);
  };

  const goToPrevStep = () => {
    if (step === RegisterSteps.REGISTER) setStep(RegisterSteps.USER_DETAILS);
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    // eslint-disable-next-line no-underscore-dangle
    const _step = params.get('step');
    if (_step === RegisterSteps.VERIFY_EMAIL) {
      setStep(_step);
    }
  }, [search]);

  return (
    <RegisterContext.Provider
      value={{ step, newUser, userRegistered, setNewUser, goToNextStep, goToPrevStep }}
    >
      {children}
    </RegisterContext.Provider>
  );
};

RegisterProvider.propTypes = {
  children: PropTypes.node,
};

RegisterProvider.defaultProps = {
  children: '',
};

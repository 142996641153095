import React from 'react';
import PropTypes from 'prop-types';
import { Box, Modal } from '@mui/material';

export const VIModal = ({ show, toggle }) => (
  <Modal id="modal__withdraw" open={show} onClose={() => toggle(false)} keepMounted>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '20px',
        p: 4,
      }}
    >
      <div id="veriff-root" />
    </Box>
  </Modal>
);

VIModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

VIModal.defaultProps = {};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@mui/material';

export const Password = (props) => {
  const [hidden, setHidden] = useState(true);
  const ToogleShow = (e) => {
    e.preventDefault();
    setHidden(!hidden);
  };
  const { placeholder, name, value, onChange, onBlur, error, helperText } = props;
  return (
    <Box sx={{ position: 'relative' }}>
      <TextField
        fullWidth
        placeholder={placeholder}
        type={hidden ? 'password' : 'text'}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
      />
      <Box
        onClick={ToogleShow}
        component="span"
        sx={{
          position: 'absolute',
          right: '12px',
          top: '16px',
          color: '#320a8d',
          fontSize: '24px',
          cursor: 'pointer',
        }}
      >
        <i className={`fa ${hidden ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true" />
      </Box>
    </Box>
  );
};

Password.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

Password.defaultProps = {
  error: false,
  helperText: '',
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card as MuiCard } from '@mui/material';

export const Card = ({ children, ...props }) => {
  return (
    <MuiCard
      {...props}
      sx={{
        width: '100%',
        mt: { xs: 0, sm: 10 },
        borderRadius: { xs: 0, sm: '20px' },
        background: { xs: 'transparent', sm: '#fff' },
        boxShadow: {
          xs: 'none',
          sm: '0px 1px 5px 0px rgba(233, 235, 250, 0.20), 0px 2px 2px 0px rgba(233, 235, 250, 0.50), 0px 3px 1px -2px #E9EBFA',
        },
        py: { xs: '36px', sm: '200px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '40px',
          maxWidth: { sm: '352px' },
          mx: { sm: 'auto' },
        }}
      >
        {children}
      </Box>
    </MuiCard>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

import { Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export const RegisterPageHeader = ({ title }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Typography
      color="primary"
      variant={isMobile ? 'h6' : 'h4'}
      fontWeight={isMobile ? 600 : 500}
      fullWidth
    >
      {title}
    </Typography>
  );
};
RegisterPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

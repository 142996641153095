import { Box, Button, Typography, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CheckCircleFilledImg from '../../../assets/images/CheckCircleFilled.png';
import { resendEmailVerification, verifyEmail } from '../../../service/user.service';
import { startGlobalLoading, finishGlobalLoading } from '../../../store/action';
import { Routes } from '../../../routes';
import { getJwtPayload } from '../../../utils/jwt';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { RegisterPageHeader } from '../RegisterPageHeader';
import { useRegisterContext } from '../RegisterProvider';

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');
  const auth = useSelector((state) => state.auth);
  const { token: accessToken, user } = auth;
  const { userRegistered, newUser, goToNextStep } = useRegisterContext();
  const { openSnackbar } = useSnackbar();
  const [verified, setVerified] = useState(false);
  const [resentVerification, setResentVerification] = useState(false);
  const history = useHistory();

  const handleVerification = (e) => {
    e.preventDefault();
    if (!token) {
      openSnackbar('token requried', 'error');
      return;
    }

    dispatch(startGlobalLoading());
    return verifyEmail(token)
      .then(() => {
        dispatch({ type: 'AUTH_SIGN_IN', payload: true });
        const id = getJwtPayload(token);
        dispatch({ type: 'SET_USER', payload: { ...user, id, isEmailVerified: true } });
        setVerified(true);
      })
      .catch((err) => openSnackbar(err.message, 'error'))
      .finally(() => dispatch(finishGlobalLoading()));
  };

  const resendVerification = (e) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    return resendEmailVerification(accessToken)
      .then(() => setResentVerification(true))
      .catch((err) => openSnackbar(err.message, 'error'))
      .finally(() => dispatch(finishGlobalLoading()));
  };

  if (!userRegistered && !accessToken && !token) {
    history.push({ pathname: Routes.Home.path });
  }

  return (
    <Stack
      spacing="44px"
      sx={{
        maxWidth: '352px',
        mx: 'auto',
        pt: { xs: '64px', sm: '220px' },
        pb: { xs: '64px', sm: '170px' },
      }}
    >
      <RegisterPageHeader title="Verify email" />
      {(accessToken || token) && (
        <>
          {verified && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Box component="img" src={CheckCircleFilledImg} alt="check" />
              <Typography color="primary" variant="body2" fontWeight={600}>
                You are ready to go. Your email has been successfully verified!
              </Typography>
            </Box>
          )}
          {!verified && !resentVerification && !token && accessToken && (
            <Typography color="primary">
              Hi{' '}
              <Typography component="span" fontWeight={500}>
                {newUser?.name}
              </Typography>
              ,
              <br />
              <br />
              We’ve sent an email to{' '}
              <Typography component="span" fontWeight={500}>
                {newUser?.email}
              </Typography>
              , please check your inbox and verify your email. If you can’t find our email, please
              check junk junk/spam email folder.
            </Typography>
          )}
          {!verified && resentVerification && !token && accessToken && (
            <Typography color="primary" variant="body2" fontWeight={600}>
              Verification email is resent. Please check your email.
            </Typography>
          )}
          {!verified && token && (
            <Typography color="primary" variant="body2" fontWeight={600}>
              Please click below to continue.
            </Typography>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {!verified && (
              <>
                {token && (
                  <Button color="primary" variant="contained" onClick={handleVerification}>
                    Verify email
                  </Button>
                )}
                {!token && accessToken && (
                  <Button color="primary" variant="outlined" onClick={resendVerification}>
                    Resend verification email
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => history.push({ pathname: Routes.Home.path })}
                >
                  Cancel
                </Button>
              </>
            )}
            {verified && (
              <Button color="primary" variant="contained" onClick={() => goToNextStep()}>
                Next
              </Button>
            )}
          </Box>
        </>
      )}
    </Stack>
  );
};

export default VerifyEmail;

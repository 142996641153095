import * as EmailValidator from 'email-validator';
import { errors } from '../constants';
import api from './api';

export const register = async (user) => {
  if (!user.name) throw new Error('name required');
  if (!user.password) throw new Error('password required');
  if (user.password.length < 5) throw new Error('password is at least 5 length');
  if (!user.email) throw new Error('email required');
  if (!user.areTermsAndConditionsAccepted)
    throw new Error('Terms and conditions should be accepted');
  if (user.email && !EmailValidator.validate(user.email)) {
    throw new Error('Invalid email');
  }

  return api
    .post(`/v1/auth/register`, { ...user })
    .then((response) => {
      if (response) {
        const { tokens } = response.data;
        localStorage.setItem('token', tokens.access.token);
        localStorage.setItem('refreshToken', tokens.refresh.token);
        return {
          user: response.data.user,
          token: tokens.access.token,
          refreshToken: tokens.refresh.token,
        };
      }
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const signIn = async ({ email, password, hcaptchaToken }) => {
  return api
    .post(`/v1/auth/login`, { email, password, hcaptchaToken })
    .then((response) => {
      const { user, tokens } = response.data;
      localStorage.setItem('token', tokens.access.token);
      localStorage.setItem('refreshToken', tokens.refresh.token);
      return { user, token: tokens.access.token, refreshToken: tokens.refresh.token };
    })
    .catch((err) => {
      if (err.response) throw new Error(err.response.data.message);
      else throw new Error('Network Error');
    });
};

export const update = async (id, user) =>
  api
    .patch(`/v1/users/${id}`, user)
    .then((response) => {
      if (response) {
        return response.data;
      }
    })
    .catch((err) => {
      if (err.response) throw new Error(err.response.data.message);
      else throw new Error('Network Error');
    });

export const updatePassword = async (id, body) =>
  api
    .post(`/v1/users/${id}/update-password`, body)
    .then((response) => {
      if (response) {
        const { tokens } = response.data;
        console.log(tokens);
        localStorage.setItem('token', tokens.access.token);
        localStorage.setItem('refreshToken', tokens.refresh.token);
        return response.data;
      }
    })
    .catch((err) => {
      if (err.response) throw new Error(err.response.data.message);
      else throw new Error('Network Error');
    });

export const logOut = async (refreshToken) =>
  api
    .post(`/v1/auth/logout`, { refreshToken })
    .then((response) => {
      if (response && response.status === 204) {
        localStorage.removeItem('token');
        return true;
      }
      return false;
    })
    .catch((err) => {
      if (err.response) throw new Error(err.response.data.message);
      else throw new Error('Network Error');
    });

export const forgotPassword = async (email) => {
  if (!email) throw new Error('email required');

  return api.post(`/v1/auth/forgot-password`, { email }).catch((err) => {
    if (err.response) throw new Error(err.response.data.message);
    else throw new Error('Network Error');
  });
};

export const resetPassword = async (password, token) => {
  if (!password) throw new Error(errors.errorMessage.requirePassword);
  if (!token) throw new Error(errors.errorMessage.requireRestPasswordToken);

  return api.post(`/v1/auth/reset-password`, { password }, { params: { token } }).catch((err) => {
    if (err.response) throw new Error(err.response.data.message);
    else throw new Error('Network Error');
  });
};

export const getMyAccount = async (id) =>
  api
    .get(`/v1/users/${id}`)
    .then((response) => {
      if (response) return response.data;
      return null;
    })
    .catch((err) => {
      if (err.response) throw new Error(err.response.data.message);
      else throw new Error('Network Error');
    });

export const verifyEmail = async (token) =>
  api
    .post(`/v1/auth/verify-email`, {}, { params: { token } })
    .then((response) => {
      if (response && response.status === 204) {
        return true;
      }
      throw new Error('Failed to verify email');
    })
    .catch((err) => {
      if (err.response) throw new Error(err.response.data.message);
      else throw new Error('Network Error');
    });

export const sendNewsletterSignup = async (data) =>
  api
    .post(`/v1/auth/register-interest`, data)
    .then((response) => {
      if (response && response.status === 204) {
        return true;
      }
      throw new Error('Failed to send email verification');
    })
    .catch((err) => {
      if (err.response) throw new Error(err.response.data.message);
      else throw new Error('Network Error');
    });

export const resendEmailVerification = async () =>
  api
    .post(`/v1/auth/send-verification-email`)
    .then((response) => {
      if (response && response.status === 204) {
        return true;
      }
      throw new Error('Failed to send email verification');
    })
    .catch((err) => {
      if (err.response) throw new Error(err.response.data.message);
      else throw new Error('Network Error');
    });

export const setQuestionnaire = async (id, taskQuestion, tasks, referQuestion, referPlace) => {
  const body = {
    questionnaire: [
      {
        q: taskQuestion,
        a: tasks,
      },
      {
        q: referQuestion,
        a: referPlace,
      },
    ],
  };
  return api
    .post(`/v1/users/${id}/misc`, body)
    .then((response) => response?.data)
    .catch((err) => {
      if (err.response) throw new Error(err.response.data.message);
      else throw new Error('Network Error');
    });
};

export const postVeriffSessionId = async ({ veriffUserId }) => {
  const body = {
    veriffUserId,
  };

  return api
    .post(`/v1/users/kyc/started`, body)
    .then((response) => response?.data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const suspendUsers = async (userEmails, suspendStatus) => {
  const emails = userEmails.split(',').map((item) => item.trim());
  const body = {
    userEmails: emails,
    suspendStatus,
  };

  return api
    .post(`/v1/users/suspend`, body)
    .then((response) => {
      if (response) {
        return response.data;
      }
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const unsuspendUsers = async (userEmails) => {
  const emails = userEmails.split(',').map((item) => item.trim());
  const body = {
    userEmails: emails,
  };

  return api
    .post(`/v1/users/unsuspend`, body)
    .then((response) => {
      if (response && response.status === 200) {
        return true;
      }
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const deleteUsersByEmail = async (userEmails) => {
  const emails = userEmails.split(',').map((item) => item.trim());
  const body = {
    emails,
  };

  return api
    .post(`/v1/users/delete/emails`, body)
    .then((response) => {
      if (response && response.status === 200) {
        return {
          message: 'Users has been deleted',
          failedEmails: response.data.failedEmails,
        };
      }
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const bindCvatAccount = async (userId) => {
  return api
    .post(`/v1/users/${userId}/cvat/bind`)
    .then((response) => response?.data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const getCvatJobs = async (userId, isAssigned) => {
  return api
    .get(`/v1/users/${userId}/cvat/tasks`, {
      params: {
        isAssigned,
      },
    })
    .then((response) => response?.data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const assignJob = async (userId, cvatTaskId) => {
  return api
    .post(`/v1/users/${userId}/cvat/tasks/assign`, { cvatTaskId })
    .then((response) => response?.data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const makeCvatTesters = async (userEmails) => {
  const emails = userEmails.split(',').map((item) => item.trim());
  const body = {
    userEmails: emails,
  };

  return api
    .post(`/v1/users/cvat/beta`, body)
    .then((response) => {
      if (response) {
        return {
          failedEmails: response.data.failedEmails,
        };
      }
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axiosInstance from './api';
import { setUserDetails, signOut } from '../store/action';
import { getJwtPayload } from '../utils/jwt';

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (axiosConfig) => {
      const token = localStorage.getItem('token');
      if (token) {
        axiosConfig.headers.Authorization = `Bearer ${token}`;
      }

      return axiosConfig;
    },
    (error) => Promise.reject(error),
  );

  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (err.config.url === '/v1/auth/refresh-tokens' && err.response) {
        dispatch(signOut());
        return Promise.reject(err);
      }
      if (err.config.url !== '/v1/auth/login' && err.response) {
        if (
          err.response.status !== 401 ||
          (err.config.headers && err.config.headers['x-no-retry'])
        ) {
          return Promise.reject(err);
        }

        try {
          if (!err.config.headers) {
            err.config.headers = {};
          }
          err.config.headers['x-no-retry'] = 'true';

          const refreshToken = localStorage.getItem('refreshToken');
          const rs = await axiosInstance.post('/v1/auth/refresh-tokens', {
            refreshToken,
          });
          const { access, refresh } = rs.data;
          const userId = getJwtPayload(access.token);
          const { data: user } = await axiosInstance.get(`/v1/users/${userId}`);
          dispatch(setUserDetails(user));

          localStorage.setItem('token', access.token);
          localStorage.setItem('refreshToken', refresh.token);

          return axiosInstance(err.config);
        } catch (_error) {
          console.log(_error);
        }
      }
      return Promise.reject(err);
    },
  );
};

export default setup;

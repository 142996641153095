import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { AppBar, Box, Button, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { errors as errorsConstants } from '../../constants';
import { Routes } from '../../routes';
import { forgotPassword, resetPassword } from '../../service/user.service';
import { Password } from '../../ui/password';
import { ResetPasswordValidationSchema } from './schema';
import { EmailValidationSchema } from '../../utils/validation-schemas';
import logoImg from '../../assets/images/logo.png';
import { useSnackbar } from '../../providers/SnackbarProvider';

export const resetPasswordStep = {
  verifyEmail: 'verifyEmail',
  pending: 'pending',
  resetPassword: 'resetPassword',
};

const ForgotPasswordPage = (props) => {
  const { history } = props;
  const { search } = useLocation();
  const verificationToken = search.replace('?token=', '');
  // const { token } = useSelector(state => state.auth);
  // const [submittable, setSubmittable] = useState(false);
  const [step, setStep] = useState(
    verificationToken ? resetPasswordStep.resetPassword : resetPasswordStep.verifyEmail,
  );
  const [email, setEmail] = useState('');
  const { openSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const sendForgotPasswordRequest = (toEmail) => {
    if (!toEmail) {
      openSnackbar(errorsConstants.errorMessage.requireEmail, 'error');
      return;
    }

    return forgotPassword(toEmail)
      .then(() => {
        setEmail(toEmail);
        setStep(resetPasswordStep.pending);
      })
      .catch((err) => {
        openSnackbar(err.message, 'error');
      });
  };

  const handleForgotPassword = (data, { setSubmitting }) => {
    setSubmitting(true);
    sendForgotPasswordRequest(data.email);
    setSubmitting(false);
  };

  const handleResendForgotPassword = (e) => {
    e.preventDefault();
    if (!email) sendForgotPasswordRequest(email);
    else setStep(resetPasswordStep.verifyEmail);
  };

  const handleResetPassword = (data, { setSubmitting }) => {
    setSubmitting(true);
    resetPassword(data.password, verificationToken)
      .then(() => {
        setSubmitting(false);
        history.push({ pathname: Routes.Login.path });
      })
      .catch((err) => {
        setSubmitting(false);
        openSnackbar(err.message, 'error');
      });
  };

  return (
    <Box
      sx={{
        backgroundColor: { xs: '#fff', sm: '#F6F7FE' },
        px: { xs: '8px', sm: '24px', md: '72px', lg: '100px', xl: '224px' },
        pt: '76px',
        minHeight: '100vh',
        boxSizing: 'border-box',
      }}
    >
      <AppBar
        component="nav"
        sx={{ backgroundColor: { xs: '#fff', sm: '#F6F7FE' }, boxShadow: 'none' }}
      >
        <Toolbar>
          <img src={logoImg} alt="HUMAN App" style={{ width: 135 }} />
        </Toolbar>
      </AppBar>
      <Box>
        <Typography
          color="primary"
          variant={isMobile ? 'h6' : 'h4'}
          fontWeight={600}
          textAlign="center"
          mb={2}
        >
          {step === resetPasswordStep.verifyEmail && <h2>Forgot your password?</h2>}
          {step === resetPasswordStep.pending && <h2>Check your email</h2>}
          {step === resetPasswordStep.resetPassword && <h2>Create new password</h2>}
        </Typography>
        {step === resetPasswordStep.verifyEmail && (
          <Typography color="primary" textAlign="center">
            A link will be sent to your registered email address. Go to the link and create a new
            password.
          </Typography>
        )}
        {step === resetPasswordStep.pending && (
          <Typography color="primary" textAlign="center">
            Please check your email and follow instructions to change your password.
          </Typography>
        )}
        {step === resetPasswordStep.resetPassword && (
          <Typography color="primary" textAlign="center">
            Your password should be different form the previously created password
          </Typography>
        )}
        <Box sx={{ maxWidth: '352px', mx: 'auto', mt: 3 }}>
          {step === resetPasswordStep.verifyEmail && (
            <Formik
              initialValues={{ email: '' }}
              validationSchema={EmailValidationSchema}
              onSubmit={handleForgotPassword}
            >
              {({ errors, touched, values, setFieldValue, handleSubmit, isValid, dirty }) => (
                <form style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                  <TextField
                    fullWidth
                    name="email"
                    value={values.email}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    placeholder="Email"
                    type="email"
                    error={touched.email && errors.email}
                    helperText={errors.email}
                  />
                  <Box sx={{ display: 'flex', width: '100%', gap: 3 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => history.goBack()}
                      fullWidth
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={!(isValid && dirty)}
                      fullWidth
                    >
                      Next
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          )}
          {step === resetPasswordStep.pending && (
            <Typography color="primary" textAlign="center">
              Did not recieve mail?{' '}
              <Typography
                component="span"
                color="secondary"
                sx={{ cursor: 'pointer' }}
                onClick={handleResendForgotPassword}
              >
                Re-send.
              </Typography>
            </Typography>
          )}
          {step === resetPasswordStep.resetPassword && (
            <Formik
              initialValues={{ password: '', repeatPassword: '' }}
              validationSchema={ResetPasswordValidationSchema}
              onSubmit={handleResetPassword}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
                dirty,
                values,
              }) => (
                <form style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                  <Password
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    value={values.password}
                    placeholder="Create password"
                    error={touched.password && errors.password}
                    helperText={errors.password}
                  />
                  <Password
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="repeatPassword"
                    value={values.repeatPassword}
                    placeholder="Confirm password"
                    error={touched.repeatPassword && errors.repeatPassword}
                    helperText={errors.repeatPassword}
                  />

                  <Box sx={{ display: 'flex', width: '100%', gap: 3 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => history.goBack()}
                      fullWidth
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={!(isValid && dirty)}
                      fullWidth
                    >
                      Save
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          )}
        </Box>
      </Box>
    </Box>
  );
};
ForgotPasswordPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};
export default ForgotPasswordPage;

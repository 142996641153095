import { useState } from 'react';
import dayjs from 'dayjs';
import { getStats } from '../service/labelingHcaptcha.service';
import usePollingEffect from './usePollingEffect';
import { config } from '../config';

export default function useCaptchaStats({ siteKey }) {
  const [servedCaptchas, setServedCaptchas] = useState(0);
  const [solvedCaptchas, setSolvedCaptchas] = useState(0);
  const [currentDateSolvedCaptchas, setCurrentDateSolvedCaptchas] = useState(0);
  const [hmtTotal, setHmtTotal] = useState(0);
  const [hmtRecent, setHmtRecent] = useState(0);
  const [error, setError] = useState(null);

  const getCaptchaStats = () => {
    getStats()
      .then((stats) => {
        const currentDate = dayjs().format('YYYY-MM-DD');
        setServedCaptchas(stats.served);
        setSolvedCaptchas(stats.solved);
        setCurrentDateSolvedCaptchas(stats.currentDateStats[currentDate]?.solved || 0);
        setHmtTotal(stats?.balance?.total || 0);
        setHmtRecent(stats?.balance?.recent || 0);
      })
      .catch((err) => {
        setError(err);
      });
  };

  usePollingEffect(
    () => {
      if (siteKey) {
        getCaptchaStats();
      }
    },
    [siteKey],
    { interval: config.captchaStatsPollingInterval * 1000 },
  );

  return {
    servedCaptchas,
    solvedCaptchas,
    currentDateSolvedCaptchas,
    hmtTotal,
    hmtRecent,
    error,
    getCaptchaStats,
  };
}

import { Box, Button as MuiButton, Link, TextField, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../routes';
import { WalletAddressValidationSchema } from '../../../components/update-wallet-form/schema';
import { finishGlobalLoading, startGlobalLoading } from '../../../store/action';
import { update } from '../../../service/user.service';
import CheckCircleFilledImg from '../../../assets/images/CheckCircleFilled.png';
import { RegisterPageHeader } from '../RegisterPageHeader';
import { useSnackbar } from '../../../providers/SnackbarProvider';

const ConnectWallet = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isSuccess, setIsSuccess] = useState(false);
  const initialValues = {
    polygonWalletAddr: '',
  };
  const history = useHistory();
  const { openSnackbar } = useSnackbar();

  const handleUpdateWalletAddress = ({ polygonWalletAddr }, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(startGlobalLoading());
    update(user?.id, { polygonWalletAddr })
      .then((userRes) => {
        if (userRes) {
          setIsSuccess(true);
          dispatch({ type: 'SET_USER', payload: userRes });
        } else {
          openSnackbar('Failed to update profile', 'error');
        }
        setSubmitting(false);
      })
      .catch((err) => {
        openSnackbar(err.message, 'error');
        setSubmitting(false);
      })
      .finally(() => dispatch(finishGlobalLoading()));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        maxWidth: '352px',
        mx: 'auto',
        py: { xs: '64px', sm: '210px' },
      }}
    >
      <RegisterPageHeader title="Connect Wallet" />
      {!isSuccess ? (
        <Typography color="primary">
          The HUMAN App runs on Polygon mainnet. Please make sure you enter your polygon wallet
          address.{' '}
          <Link href="#" sx={{ fontWeight: 600 }}>
            Learn more.
          </Link>
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Box component="img" src={CheckCircleFilledImg} alt="check" />
          <Typography variant="body2" color="primary" fontWeight={600}>
            Success! Your wallet is now connected!
          </Typography>
        </Box>
      )}
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={WalletAddressValidationSchema}
          onSubmit={handleUpdateWalletAddress}
        >
          {({
            errors,
            touched,
            isValid,
            dirty,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                marginBottom: '16px',
              }}
            >
              <TextField
                fullWidth
                placeholder="Wallet address"
                type="text"
                name="polygonWalletAddr"
                value={values.polygonWalletAddr}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue('polygonWalletAddr', e.target.value)}
                error={touched.polygonWalletAddr && errors.polygonWalletAddr}
                helperText={errors.polygonWalletAddr}
                inputProps={{ sx: { fontSize: '12px' } }}
                disabled={isSuccess}
              />
              {!isSuccess && (
                <MuiButton
                  fullWidth
                  color="primary"
                  variant="contained"
                  disabled={!(isValid && dirty)}
                  onClick={handleSubmit}
                >
                  Connect Wallet
                </MuiButton>
              )}
            </Form>
          )}
        </Formik>
        {isSuccess ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {/* <MuiButton color="primary" variant="outlined" fullWidth>
              Disconnect wallet
            </MuiButton> */}
            <MuiButton
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push(Routes.Workspace.Profile.path)}
            >
              Go to Dashboard
            </MuiButton>
          </Box>
        ) : (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}
          >
            <MuiButton
              color="primary"
              variant="outlined"
              fullWidth
              onClick={() => history.push(Routes.Home.path)}
            >
              Cancel
            </MuiButton>
            <MuiButton
              color="primary"
              variant="outlined"
              fullWidth
              onClick={() => history.push(Routes.Workspace.Labeling.HCaptcha)}
            >
              Skip for now
            </MuiButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ConnectWallet;

import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Modal,
  Typography,
  FormHelperText,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { UpdatePasswordValidationSchema } from '../../pages/Profile/schema';
import { updatePassword } from '../../service/user.service';
import { config } from '../../config';
import { Password } from '../../ui/password';
import { useSnackbar } from '../../providers/SnackbarProvider';

export const UpdatePasswordModalForm = ({ onSuccess, onCancel }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { openSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const captchaRef = useRef(null);

  const handleUpdatePassword = (
    { currentPassword, newPassword, hcaptchaToken },
    { setSubmitting },
  ) => {
    setSubmitting(true);
    updatePassword(user.id, { currentPassword, newPassword, hcaptchaToken })
      .then((userRes) => {
        if (userRes) {
          const { user: newUser } = userRes;
          dispatch({ type: 'SET_USER', payload: newUser });
          openSnackbar('Password updated!', 'success');
          onSuccess();
        } else {
          openSnackbar('Failed to update password', 'error');
        }
        setSubmitting(false);
      })
      .catch((err) => {
        openSnackbar(err.message, 'error');
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      hcaptchaToken: '',
    },
    validationSchema: UpdatePasswordValidationSchema,
    onSubmit: handleUpdatePassword,
  });

  useEffect(() => {
    formik.resetForm();
    captchaRef?.current?.resetCaptcha();
  }, []);

  return (
    <Box sx={{ width: 303, mx: 'auto' }}>
      <Typography
        color="text.primary"
        variant={isMobile ? 'h6' : 'h5'}
        fontWeight={500}
        textAlign={isMobile ? 'left' : 'center'}
        mb={{ xs: 2, sm: 4 }}
      >
        Update password
      </Typography>
      <form name="form" onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '16px', sm: '24px' } }}>
          <Password
            onChange={(e) => formik.setFieldValue('currentPassword', e.target.value)}
            onBlur={formik.handleBlur}
            name="currentPassword"
            value={formik.values.currentPassword}
            placeholder="Current Password"
            error={formik.touched.currentPassword && formik.errors.currentPassword}
            helperText={formik.errors.currentPassword}
          />
          <Password
            onChange={(e) => formik.setFieldValue('newPassword', e.target.value)}
            onBlur={formik.handleBlur}
            name="newPassword"
            value={formik.values.newPassword}
            placeholder="New Password"
            error={formik.touched.newPassword && formik.errors.newPassword}
            helperText={formik.errors.newPassword}
          />
          <Password
            onChange={(e) => formik.setFieldValue('newPasswordConfirmation', e.target.value)}
            onBlur={formik.handleBlur}
            name="newPasswordConfirmation"
            value={formik.values.newPasswordConfirmation}
            placeholder="Confirm Password"
            error={formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation}
            helperText={formik.errors.newPasswordConfirmation}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <HCaptcha
              sitekey={config.hcaptchaSiteKey}
              endpoint={config.hcaptchaExchangeUrl}
              reportapi={config.hcaptchaLabelingBaseUrl}
              custom
              onVerify={(token) => formik.setFieldValue('hcaptchaToken', token)}
              ref={captchaRef}
            />
            {formik.errors.hcaptchaToken && (
              <FormHelperText sx={{ mx: '14px', mt: '3px' }} error>
                {formik.errors.captchPassRequired}
              </FormHelperText>
            )}
          </Box>
          {!isMobile ? (
            <Button
              fullWidth
              color="primary"
              variant="contained"
              disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
              onClick={formik.handleSubmit}
            >
              Update password
            </Button>
          ) : (
            <Stack direction="row" spacing="12px">
              <Button fullWidth variant="outlined" color="primary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
                onClick={formik.handleSubmit}
              >
                Update
              </Button>
            </Stack>
          )}
        </Box>
      </form>
    </Box>
  );
};
UpdatePasswordModalForm.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};
UpdatePasswordModalForm.defaultProps = {
  onSuccess: () => {},
  onCancel: () => {},
};

export const UpdatePasswordModal = ({ show, toggle }) => {
  return (
    <Modal id="modal__connect_wallet" open={show} onClose={() => toggle(false)} keepMounted>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: 560,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '20px',
          py: 8,
        }}
      >
        <UpdatePasswordModalForm onSuccess={() => toggle(false)} />
      </Box>
    </Modal>
  );
};

UpdatePasswordModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

UpdatePasswordModal.defaultProps = {};

import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useVeriff } from './useVeriff';
import { VIModal } from './vi-modal';

export default function VeriffButton() {
  const [show, setShow] = useState(false);
  const onVIClick = () => setShow((prev) => !prev);

  useVeriff({ show, setShow });

  return (
    <>
      <Button color="primary" variant="outlined" onClick={onVIClick}>
        Complete KYC
      </Button>
      <VIModal show={show} toggle={setShow} />
    </>
  );
}

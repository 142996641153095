import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export const Footer = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (isMobile) return <></>;

  return (
    <Box sx={{ py: 5 }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5, fontSize: 12, mb: 2 }}>
        <Link color="text.secondary" href="https://humanprotocol.org/app/privacy-policy">
          Privacy Policy
        </Link>
        <Link color="text.secondary" href="https://humanprotocol.org/app/terms-and-conditions">
          Terms and conditions
        </Link>
        <Link color="text.secondary" href="https://humanprotocol.org">
          HUMAN Protocol
        </Link>
      </Box>
      <Typography color="text.secondary" variant="caption" component="p">
        © 2021 HPF. HUMAN Protocol® is a registered trademark
      </Typography>
    </Box>
  );
};

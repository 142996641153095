import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SmoothScroll from 'smooth-scroll';
import { createBrowserHistory } from 'history';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import CookieConsent from 'react-cookie-consent';

// import Navigation from './components/navigation';
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import ForgotPasswordPage from './pages/ForgotPassword';
import VerifyEmail from './pages/VerifyEmail';
import ProtocolDisclaimerPage from './pages/ProtocolDisclaimer';

// import { Subscribe } from './pages/Home/subscribe';
import { Routes } from './routes';
import ScrollToTop from './ui/scroll-to-top';
import Loader from './ui/loader';
import theme from './theme';

import './App.scss';
import Workspace from './pages/Workspace';

import 'react-toastify/dist/ReactToastify.css';
import SnackbarProvider from './providers/SnackbarProvider';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const history = createBrowserHistory();
  const { isLoading } = useSelector((state) => state.app);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <Router history={history}>
          {/* <Navigation /> */}
          <ScrollToTop>
            <Switch>
              <Route exact path={Routes.Home.path} component={HomePage} />
              <Route exact path={Routes.Login.path} component={LoginPage} />
              <Route exact path={Routes.Register.path} component={RegisterPage} />
              <Route exact path={Routes.ForgotPassword.path} component={ForgotPasswordPage} />
              <Route exact path={Routes.VerifyEmail.path} component={VerifyEmail} />
              <Route path={Routes.Workspace.path} component={Workspace} />
              <Route
                exact
                path={Routes.ProtocolDisclaimer.path}
                component={ProtocolDisclaimerPage}
              />
              <Redirect from="*" to="/" />
            </Switch>
          </ScrollToTop>
          {/* <Subscribe history={history} /> */}
          <Loader isOpen={isLoading} />
        </Router>
        <CookieConsent
          location="bottom"
          buttonClasses="btn btn-custom form-control"
          buttonText="Accept"
          cookieName="cookiesConsent"
          style={{
            width: 'auto',
            color: '#320a8d',
            background: '#fff',
            border: '1px solid #e9ebfa',
            borderRadius: '30px',
            marginBottom: '80px',
            left: 'auto',
            right: '12px',
            padding: '0px 8px',
            fontSize: '12px',
            alignItems: 'center',
          }}
          expires={30}
        >
          We use cookies. By using this site, you consent to our{' '}
          <span>
            <a
              href="https://humanprotocol.org/app/privacy-policy"
              target="_blank"
              rel="noreferrer noopener"
              style={{ fontSize: '14px' }}
            >
              Privacy Policy
            </a>
          </span>
        </CookieConsent>
        <ToastContainer />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { Routes } from '../../routes';
import { startGlobalLoading, finishGlobalLoading } from '../../store/action';
import { resendEmailVerification, verifyEmail } from '../../service/user.service';
import { useSnackbar } from '../../providers/SnackbarProvider';

const VerifyEmail = ({ history }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const token = search.replace('?token=', '');
  const auth = useSelector((state) => state.auth);
  const accessToken = auth.token;
  const { user } = auth;
  const [verified, setVerified] = useState(false);
  const [resentVerification, setResentVerification] = useState(false);
  const { openSnackbar } = useSnackbar();

  const handleVerification = (e) => {
    e.preventDefault();
    if (!token) {
      openSnackbar('token requried', 'error');
      return;
    }
    dispatch(startGlobalLoading());
    return verifyEmail(token)
      .then(() => {
        dispatch({ type: 'AUTH_SIGN_IN', payload: true });
        dispatch({ type: 'SET_USER', payload: { ...user, isEmailVerified: true } });
        setVerified(true);
        setTimeout(() => {
          history.push({ pathname: Routes.Login.path });
        }, 3000);
      })
      .catch((err) => openSnackbar(err.message, 'error'))
      .finally(() => dispatch(finishGlobalLoading()));
  };

  const resendVerification = (e) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    return resendEmailVerification(accessToken)
      .then(() => setResentVerification(true))
      .catch((err) => openSnackbar(err.message, 'error'))
      .finally(() => dispatch(finishGlobalLoading()));
  };
  return (
    <Box
      sx={{
        borderRadius: { sm: 0, md: '40px' },
        backgroundColor: '#f2f2fa',
        mx: { sm: 0, md: '72px' },
        my: 13,
        px: '152px',
        py: '62px',
      }}
    >
      <Typography color="primary" variant="h4" fontWeight={600} textAlign="center" mb={2}>
        {verified ? 'Email verified' : 'Verify email'}
      </Typography>
      {verified && (
        <Typography color="primary" textAlign="center">
          Redirecting you to login.
        </Typography>
      )}
      {!verified && !resentVerification && !token && accessToken && (
        <Typography color="primary" textAlign="center">
          Verification email is sent. Please check your email.
        </Typography>
      )}
      {!verified && resentVerification && !token && accessToken && (
        <Typography color="primary" textAlign="center">
          Verification email is resent. Please check your email.
        </Typography>
      )}
      {!verified && token && (
        <Typography color="primary" textAlign="center">
          Please click below to continue
        </Typography>
      )}
      <Box sx={{ maxWidth: '392px', mx: 'auto', mt: 3 }}>
        <Box>
          <form style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            {!verified && (
              <>
                {!token && accessToken && (
                  <Button color="primary" variant="contained" onClick={resendVerification}>
                    Re-send
                  </Button>
                )}
                {token && (
                  <Button color="primary" variant="contained" onClick={handleVerification}>
                    Verify email
                  </Button>
                )}
              </>
            )}
          </form>
        </Box>
      </Box>
    </Box>
  );
};
VerifyEmail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default VerifyEmail;

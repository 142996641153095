import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { ConnectWalletModal } from './connect-wallet-modal';

export default function ConnectWalletButton() {
  const [show, setShow] = useState(false);
  const onClick = () => setShow((prev) => !prev);

  return (
    <>
      <Button variant="contained" color="primary" onClick={onClick}>
        Connect Wallet
      </Button>
      <ConnectWalletModal show={show} toggle={setShow} />
    </>
  );
}

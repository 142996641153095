/* eslint-disable no-nested-ternary */
import React, { useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Countdown from 'react-countdown';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Card } from '../../../../components/card';
import { Routes } from '../../../../routes';
import { verifyToken, connect } from '../../../../service/labelingHcaptcha.service';
import { startGlobalLoading, finishGlobalLoading, setUserDetails } from '../../../../store/action';
import notifier from '../../../../service/notify.service';
import { getMyAccount } from '../../../../service/user.service';
import { config } from '../../../../config';
import ConnectWalletButton from '../../../../components/connect-wallet';
import VeriffButton from '../../../../components/veriff';

import useDailySpend from '../../../../hooks/useDailySpend';
import useCaptchaStats from '../../../../hooks/useCaptchaStats';
import { UpdateWalletForm } from '../../../../components/update-wallet-form';

dayjs.extend(utc);

const FoundationHcaptcha = ({ siteKey, userId, isKYCed, polygonWalletAddr }) => {
  const captchaRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthed } = useSelector((state) => state.auth);
  const { currentDateSolvedCaptchas, error: captchaStatsError } = useCaptchaStats({ siteKey });
  const { dailyHmtSpend, error: dailySpendError } = useDailySpend({ siteKey });

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [mobileConnectWallet, setMobileConnectWallet] = useState(false);

  const getTomorrowDate = () => {
    const today = new Date();
    // Need to calculate a timezone offset
    const timeZoneOffset = today.getTimezoneOffset();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    tomorrow.setHours(7);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);

    const DAY_IN_MSS = 24 * 60 * 60 * 1000;
    const deltaDays = Math.floor((tomorrow.getTime() - today.getTime()) / DAY_IN_MSS);

    // We need to show countdown till 07:00 UTC time
    const newDateObj = new Date(
      tomorrow.getTime() - deltaDays * DAY_IN_MSS - timeZoneOffset * 60000,
    );

    return newDateObj;
  };

  if (!isAuthed) {
    history.push({ pathname: Routes.Home.path });
  }

  const onVerifyToken = (hcaptchaToken) => {
    dispatch(startGlobalLoading());
    verifyToken(hcaptchaToken)
      .then(() => notifier.success('Your input has been verified'))
      .catch((error) => {
        if (error.message) {
          notifier.error(error.message);
        } else {
          notifier.error('Something went wrong, please try again');
        }
      })
      .finally(() => {
        dispatch(finishGlobalLoading());
        captchaRef.current.resetCaptcha();
      });
  };

  const onCaptchaWidgetError = (error) => notifier.error(error);

  const connectUserToHcaptchaJobsPool = () => {
    dispatch(startGlobalLoading());

    connect()
      .then(() => notifier.success('Your account has been connected to HCaptcha jobs pool'))
      .then(() => getMyAccount(userId))
      .then((user) => dispatch(setUserDetails(user)))
      .catch((error) => {
        if (error.message) {
          notifier.error(error.message);
        } else {
          notifier.error('Something went wrong, please try again');
        }
      })
      .finally(dispatch(finishGlobalLoading()));
  };

  const onboardingMessage = useMemo(() => {
    if (!isKYCed && !polygonWalletAddr)
      return 'Complete KYC and connect wallet to start earning HMT';
    if (!isKYCed) return 'Complete KYC  to start earning HMT';
    return 'Connect wallet to start earning HMT';
  }, [isKYCed, polygonWalletAddr]);

  // FE cannot retrieve user stats
  if (captchaStatsError !== null || dailySpendError !== null) {
    return (
      <Alert severity="error" sx={{ mt: 4 }}>
        Something went wrong. Please, try again later
      </Alert>
    );
  }

  // FE can retrieve user stats
  return (
    <Card>
      <Typography lineHeight={1.5}>
        To earn HMT, you must complete data-labeling tasks. Rewards will be automatically sent to
        your wallet according to a predefined schedule
      </Typography>
      {dailyHmtSpend >= config.hmtDailyLimit ||
      currentDateSolvedCaptchas >= config.dailySolvedCaptchaLimit ? (
        <Box>
          <Typography fontWeight={500}>
            {dailyHmtSpend >= config.hmtDailyLimit
              ? "Today's HMT limit has exhausted. Please, come back in:"
              : 'This wallet address has completed its daily quota of solved tasks, please check back at:'}
          </Typography>
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', textAlign: 'center', color: '#6309ff', mt: 3 }}
          >
            <Countdown date={getTomorrowDate()} />
          </Typography>
        </Box>
      ) : (
        <>
          {!isKYCed || !polygonWalletAddr ? (
            <>
              <Typography fontWeight={500}>{onboardingMessage}</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {!polygonWalletAddr &&
                  (isMobile ? (
                    !mobileConnectWallet ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setMobileConnectWallet(true);
                        }}
                      >
                        Connect Wallet
                      </Button>
                    ) : (
                      <UpdateWalletForm
                        onCancel={() => setMobileConnectWallet(false)}
                        onSuccess={() => setMobileConnectWallet(false)}
                      />
                    )
                  ) : (
                    <ConnectWalletButton />
                  ))}
                {!isKYCed && <VeriffButton />}
              </Box>
            </>
          ) : (
            <>
              {siteKey && (
                <HCaptcha
                  endpoint={config.hcaptchaExchangeUrl}
                  reportapi={config.hcaptchaLabelingBaseUrl}
                  custom
                  className="hcaptcha-labeling-item"
                  sitekey={siteKey}
                  ref={captchaRef}
                  onVerify={onVerifyToken}
                  onError={onCaptchaWidgetError}
                />
              )}
              {!siteKey && (
                <Button variant="contained" color="primary" onClick={connectUserToHcaptchaJobsPool}>
                  Connect
                </Button>
              )}
            </>
          )}
        </>
      )}
    </Card>
  );
};

FoundationHcaptcha.propTypes = {
  siteKey: PropTypes.string,
  userId: PropTypes.string,
  isKYCed: PropTypes.bool,
  polygonWalletAddr: PropTypes.string,
};

FoundationHcaptcha.defaultProps = {
  siteKey: '10000000-ffff-ffff-ffff-000000000001',
  userId: '',
  isKYCed: false,
  polygonWalletAddr: '',
};

export default FoundationHcaptcha;

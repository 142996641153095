import api from './api';

export const verifyToken = (hcaptchaToken) => {
  return api
    .post(`/v1/labeling/hcaptcha/verify`, { hcaptchaToken })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error('Network Error');
      }
    });
};

export const connect = () => {
  return api
    .post(`/v1/labeling/hcaptcha/enable`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error('Network Error');
      }
    });
};

export const getStats = () => {
  return api
    .get(`/v1/labeling/hcaptcha/user-stats`)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error('Network Error');
      }
    });
};

export const getHmtDailySpend = () => {
  return api
    .get(`/v1/labeling/hcaptcha/daily-hmt-spend`)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error('Network Error');
      }
    });
};

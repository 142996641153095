import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { RegisterSteps, useRegisterContext } from '../RegisterProvider';

const STEPS = [
  { value: RegisterSteps.USER_DETAILS, title: 'User details' },
  { value: RegisterSteps.REGISTER, title: 'Register' },
  { value: RegisterSteps.VERIFY_EMAIL, title: 'Verify email' },
  { value: RegisterSteps.KYC, title: 'KYC' },
  { value: RegisterSteps.CONNECT_WALLET, title: 'Connect wallet' },
];

const RegisterStep = ({ index, selected, title }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          width: { xs: '32px', md: '40px' },
          height: { xs: '32px', md: '40px' },
          borderRadius: '100%',
          background: selected ? '#320A8D' : 'rgba(203, 207, 232, 0.52)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography fontSize={{ xs: '12px', md: '16px' }} color={selected ? 'white' : 'primary'}>
          {index + 1}
        </Typography>
      </Box>
      <Typography
        sx={{ ml: 2 }}
        color="primary"
        fontWeight={500}
        fontSize={{ xs: '12px', md: '16px' }}
      >
        {title}
      </Typography>
    </Box>
  );
};

RegisterStep.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

RegisterStep.defaultProps = {
  selected: false,
};

export const RegisterStepsView = () => {
  const { step } = useRegisterContext();

  const currentIndex = STEPS.findIndex((s) => s.value === step);

  return (
    <Box
      sx={{
        borderRadius: '16px',
        background: '#fff',
        boxShadow:
          '0px 1px 5px 0px rgba(233, 235, 250, 0.20), 0px 2px 2px 0px rgba(233, 235, 250, 0.50), 0px 3px 1px -2px #E9EBFA',
        pt: '14px',
        px: { sm: '12px', md: '40px', lg: '80px' },
        pb: '18px',
        display: { xs: 'none', sm: 'flex' },
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: '60px',
      }}
    >
      {STEPS.map((s, i) => (
        <RegisterStep key={s.value} index={i} title={s.title} selected={i <= currentIndex} />
      ))}
    </Box>
  );
};

import { useEffect, useRef } from 'react';

export default function usePollingEffect(
  asyncCallback,
  dependencies = [],
  { interval = 10_000, onCleanUp = () => {} } = {},
) {
  const timeoutIdRef = useRef(null);
  useEffect(() => {
    let stopped = false;
    // Side note: preceding semicolon needed for IIFEs.
    (async function pollingCallback() {
      try {
        await asyncCallback();
      } finally {
        // Set timeout after it finished, unless stopped
        timeoutIdRef.current = !stopped && setTimeout(pollingCallback, interval);
      }
    })();
    // Clean up if dependencies change
    return () => {
      stopped = true; // prevent racing conditions
      clearTimeout(timeoutIdRef.current);
      onCleanUp();
    };
  }, [...dependencies, interval]);
}

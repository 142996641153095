import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import { startGlobalLoading, finishGlobalLoading } from '../../store/action';
import { WalletAddressValidationSchema } from './schema';
import { update } from '../../service/user.service';
import { useSnackbar } from '../../providers/SnackbarProvider';

export const UpdateWalletForm = ({ onSuccess, onCancel }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const initialValues = {
    polygonWalletAddr: user?.polygonWalletAddr || '',
  };
  const { openSnackbar } = useSnackbar();

  const handleUpdateWalletAddress = ({ polygonWalletAddr }, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(startGlobalLoading());
    update(user.id, { polygonWalletAddr })
      .then((userRes) => {
        if (userRes) {
          dispatch({ type: 'SET_USER', payload: userRes });
          openSnackbar('Wallet updated!', 'success');
        } else {
          openSnackbar('Failed to update profile', 'error');
        }
        setSubmitting(false);
      })
      .catch((err) => {
        openSnackbar(err.message, 'error');
        setSubmitting(false);
      })
      .finally(() => {
        dispatch(finishGlobalLoading());
        onSuccess();
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={WalletAddressValidationSchema}
      onSubmit={handleUpdateWalletAddress}
    >
      {({ errors, touched, isValid, dirty, values, handleSubmit, handleBlur, setFieldValue }) => (
        <Form>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '16px', sm: '24px' } }}>
            <TextField
              fullWidth
              placeholder="Polygon Wallet Address"
              type="text"
              name="polygonWalletAddr"
              value={values.polygonWalletAddr}
              onBlur={handleBlur}
              onChange={(e) => setFieldValue('polygonWalletAddr', e.target.value)}
              error={touched.polygonWalletAddr && errors.polygonWalletAddr}
              helperText={errors.polygonWalletAddr}
              inputProps={{ sx: { fontSize: '12px' } }}
            />
            <Box display="flex" justifyContent="space-between" gap={2}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={!(isValid && dirty)}
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button fullWidth color="primary" variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

UpdateWalletForm.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

UpdateWalletForm.defaultProps = {
  onSuccess: () => {},
  onCancel: () => {},
};

import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar } from '@mui/material';
import logoImg from '../../assets/images/logo.png';
import { Footer } from '../../components/footer';
import UserDetails from './user-details';
import Register from './register';
import VerifyEmail from './verify-email';
import KYC from './kyc';
import ConnectWallet from './connect-wallet';
import { RegisterSteps, RegisterProvider, useRegisterContext } from './RegisterProvider';
import { RegisterStepsView } from './steps';

const RegisterView = () => {
  const { step } = useRegisterContext();

  return (
    <Box
      sx={{
        borderRadius: { xs: 0, sm: '20px' },
        backgroundColor: '#fff',
        boxShadow: {
          xs: 'none',
          sm: '0px 1px 5px 0px rgba(233, 235, 250, 0.20), 0px 2px 2px 0px rgba(233, 235, 250, 0.50), 0px 3px 1px -2px #E9EBFA',
        },
      }}
    >
      {step === RegisterSteps.USER_DETAILS && <UserDetails />}
      {step === RegisterSteps.REGISTER && <Register />}
      {step === RegisterSteps.VERIFY_EMAIL && <VerifyEmail />}
      {step === RegisterSteps.KYC && <KYC />}
      {step === RegisterSteps.CONNECT_WALLET && <ConnectWallet />}
    </Box>
  );
};

const RegisterPage = () => {
  return (
    <Box
      sx={{
        backgroundColor: { xs: '#fff', sm: '#F6F7FE' },
        px: { xs: '8px', sm: '24px', md: '72px', lg: '100px', xl: '224px' },
        pt: { xs: '12px', sm: '110px' },
        minHeight: '100vh',
        boxSizing: 'border-box',
      }}
    >
      <AppBar
        component="nav"
        sx={{ backgroundColor: { xs: '#fff', sm: '#F6F7FE' }, boxShadow: 'none' }}
      >
        <Toolbar>
          <img src={logoImg} alt="HUMAN App" style={{ width: 135 }} />
        </Toolbar>
      </AppBar>
      <RegisterProvider>
        <RegisterStepsView />
        <RegisterView />
      </RegisterProvider>
      <Footer />
    </Box>
  );
};

RegisterPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(RegisterPage);

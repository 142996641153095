import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useMediaQuery } from '@mui/material';
import FoundationHcaptcha from './hcaptcha';
import hcaptchaLabelingIcon from '../../../assets/icons/hcaptcha_labeling.svg';
import HcaptchaStatistics from '../../../components/statistics';

export default function LabelingHub({ hcaptchaSiteKey, userId, isKYCed, polygonWalletAddr }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // A dummy component which will be used for different jobs in future
  return (
    <Box>
      <Box sx={{ px: { xs: 0, sm: 6 }, position: 'relative' }}>
        <Box display="flex" alignItems="center">
          <Box
            component="img"
            src={hcaptchaLabelingIcon}
            alt="profile"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          />
          <Typography variant={isMobile ? 'h6' : 'h3'} color="primary" ml={{ xs: 0, sm: 5 }}>
            hCaptcha Labeling
          </Typography>
        </Box>
        {hcaptchaSiteKey && <HcaptchaStatistics siteKey={hcaptchaSiteKey} />}
      </Box>
      <FoundationHcaptcha
        siteKey={hcaptchaSiteKey}
        userId={userId}
        isKYCed={isKYCed}
        polygonWalletAddr={polygonWalletAddr}
      />
    </Box>
  );
}

LabelingHub.propTypes = {
  hcaptchaSiteKey: PropTypes.string,
  userId: PropTypes.string,
  isKYCed: PropTypes.bool,
  polygonWalletAddr: PropTypes.string,
};

LabelingHub.defaultProps = {
  hcaptchaSiteKey: '',
  userId: '',
  isKYCed: false,
  polygonWalletAddr: '',
};

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Card, Typography, useMediaQuery } from '@mui/material';
import { Routes } from '../../../routes';
import SuspendUsers from './suspend-users';
import UnsuspendUsers from './unsuspend-users';
import DeleteUsersByEmail from './delete-users-by-email';
import MakeCvatTesters from './make-cvat-testers';

export default function AdminPanel({ isUserAdmin }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const history = useHistory();
  if (!isUserAdmin) {
    history.push(Routes.Workspace.path);
  }
  return (
    <Box sx={{ px: { xs: 0, md: 6 } }}>
      <Typography variant={isMobile ? 'h6' : 'h3'} color="primary" ml={{ xs: 0, md: 5 }}>
        Admin Panel
      </Typography>
      <Card
        sx={{
          width: '100%',
          mt: { xs: 0, sm: 6, md: 10 },
          borderRadius: { xs: 0, sm: '20px' },
          background: { xs: 'transparent', sm: '#fff' },
          boxShadow: {
            xs: 'none',
            sm: '0px 1px 5px 0px rgba(233, 235, 250, 0.20), 0px 2px 2px 0px rgba(233, 235, 250, 0.50), 0px 3px 1px -2px #E9EBFA',
          },
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            maxWidth: { xs: '100%', sm: '786px' },
            mx: { sm: 'auto' },
            my: { xs: 2, sm: 5, md: 10 },
          }}
        >
          <Box sx={{ py: { xs: 2, md: 4 }, borderBottom: '1px solid #CBCFE6' }}>
            <SuspendUsers />
          </Box>
          <Box sx={{ py: { xs: 2, md: 4 }, borderBottom: '1px solid #CBCFE6' }}>
            <UnsuspendUsers />
          </Box>
          <Box sx={{ py: { xs: 2, md: 4 }, borderBottom: '1px solid #CBCFE6' }}>
            <DeleteUsersByEmail />
          </Box>
          <Box sx={{ py: { xs: 2, md: 4 } }}>
            <MakeCvatTesters />
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

AdminPanel.propTypes = {
  isUserAdmin: PropTypes.bool,
};

AdminPanel.defaultProps = {
  isUserAdmin: false,
};
